import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useState, Fragment } from "react";
import ListIcon from "@mui/icons-material/List";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import { RouteIdEnum } from "../core/enums/routes.enum";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { logOut, selectCurrentUser } from "../redux/slices/authSlice";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import BarChartIcon from '@mui/icons-material/BarChart';
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { getUserFromLocalStorage } from "../core/services/localStorage.service";

type Anchor = "left";
export default function AdminMenu() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const user = useAppSelector(selectCurrentUser);
let myId: any
  if(user){
    myId=user.id
  }else{
    myId=getUserFromLocalStorage()
  }
  const [state, setState] = useState({
    left: false,
  });

  const [open, setOpen] = useState(false);
  const [UsersReqopen, setUsersReqOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleUsersReqClick = () => {
    setUsersReqOpen(!UsersReqopen);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "left" ? 250 : undefined }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <br />
      <br />
      <List>
      <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.adminDashboard)}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.Profile)}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.Categories)}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary={"Categories"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.RequestsList)}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={"Public Requests"} />
          </ListItemButton>
        </ListItem>

        <ListItemButton
          onClick={(event) => {
            event.stopPropagation(); // add this line
            handleUsersReqClick();
          }}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Users Requests" />
          {UsersReqopen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={UsersReqopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => navigate(RouteIdEnum.PendingRequests)}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary={"Pending Requests"} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate(RouteIdEnum.FailedRequests)}
            >
              <ListItemIcon>
                <AssignmentLateIcon />
              </ListItemIcon>
              <ListItemText primary="Failed Requests" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.UsersList)}>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.Companies)}>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary={"Companies"} />
          </ListItemButton>
        </ListItem>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation(); // add this line
            handleClick();
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Manage Account" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => navigate("updateprofile/" + myId)}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={"Update Profile"} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate(RouteIdEnum.ChangePassword)}
            >
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Update Password" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("actionHistory/" + myId)}>
            <ListItemIcon>
              <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText primary={"Action History"} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
            <ListItemButton onClick={()=>navigate(RouteIdEnum.Roles)}>
              <ListItemIcon>
                <AdminPanelSettingsIcon/>
              </ListItemIcon>
              <ListItemText primary={"Roles"} /> 
            </ListItemButton>
          </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              dispatch(logOut());
              navigate(RouteIdEnum.Root);
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <Fragment>
        <IconButton
          onClick={toggleDrawer("left", true)}
          sx={{ color: "white", marginLeft: "1rem" }}
        >
          <MenuIcon sx={{ fontSize: 32 }} />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </Fragment>
    </>
  );
}
