import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Request, User } from "../../core/models/types";
import IconButton from "@mui/material/IconButton";
import { Box, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { FormControl } from "@mui/material";

import {
  useGetAssignedToUserQuery,
} from "../../redux/api/requestsApi";
import { PendingColumn } from "./requests.types";
import { ChangeEvent, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import moment from "moment";
import { Link } from "react-router-dom";
import { custom } from "../../shared/theme/theme";
const columns: readonly PendingColumn[] = [
  { id: "website", label: "Website", minWidth: 100 },
  // { id: "categories", label: "Categories", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 100 },
  { id: "updatedAt", label: "Updated At", minWidth: 100 },
  { id: "user", label: "User", minWidth: 100 },

  {
    id: "action",
    label: "Action",
    minWidth: 90,
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

function createData(
  id: String,
  website: String,
  // categories: Category[],
  createdAt: Date,
  updatedAt: Date,
  user: User,
  state: String,
  action: String
) {
  const created: string = moment.utc(createdAt).format("DD-MM-YYYY HH:mm");
  const updated: string = moment.utc(updatedAt).format("DD-MM-YYYY HH:mm");
  const username: String = user.username;
  return {
    id,
    website,
    //  categories,
    createdAt: created,
    updatedAt: updated,
    user: username,
    state,
    action: (
      <>
        <IconButton aria-label="details">
          <InfoOutlinedIcon />
        </IconButton>
        <IconButton aria-label="assign">
          <PlaylistAddIcon />
        </IconButton>
      </>
    ),
  };
}

export default function MyAssignedRequests() {
  const [filterValue, setFilterValue] = useState("all");
  const filterOptions = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "failed", label: "Failed" },
    { value: "done", label: "Done" },
  ];

  let navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: request,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAssignedToUserQuery();
  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={30} />
    <br/>
    <Skeleton variant="rectangular" height={30} />
    <br/>
    <Skeleton variant="rectangular" height={30} />
  </div>;
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!request || request.length === 0) {
    return (
      <div>
        <br />
        <br />
        <br />
        All Requests are assigned!
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
  let rows: any = [];
  request.map((req: Request) =>
    rows.push(
      createData(
        req.id,
        req.website.url,
        //   req.categories,
        req.createdAt,
        req.updatedAt,
        req.User,
        req.state,
        ""
      )
    )
  );
  rows.sort((a: any, b: any) => a.createdAt?.localeCompare(b.createdAt));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const filteredRows = rows.filter((row: any) => {
    if (filterValue === "all") {
      return true;
    } else if (filterValue === "pending") {
      return row.state === "pending";
    } else if (filterValue === "done") {
      return row.state === "done";
    } else if (filterValue === "failed") {
      return row.state === "failed";
    }
    return true;
  });

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <FormControl
        sx={{
          m: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Select
          IconComponent={FilterListRoundedIcon}
          sx={{
            '& .MuiSelect-icon': {
              color: "primary.main",
            },}}
          labelId="filter-label"
          id="filter"
          value={filterValue}
          size="small"
          onChange={(event) => {
            setFilterValue(event.target.value as string);
            setPage(0); // Reset page to first page when filter changes
          }}
        >
          {filterOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          m: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      ></FormControl>
      {err.status && <Alert severity="error">{err.msg}</Alert>}
      {success.status && <Alert severity="success">{success.msg}</Alert>}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      minWidth: column.minWidth ,
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "action") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Tooltip title="Handle Request">
                                <IconButton
                                  color="warning"
                                  onClick={() =>
                                    navigate("/updaterequest/" + row.id)
                                  }
                                >
                                  <BorderColorIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Details">
                                <IconButton
                                  aria-label="details"
                                  component="span"
                                  color="info"
                                  onClick={() =>
                                    navigate("/requestDetails/" + row.id)
                                  }
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          );
                        } else if (column.id === "website") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Link to={value}> {value}</Link>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
