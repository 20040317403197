import { RouteObject } from 'react-router-dom';
import { HomePage } from '../pages/homePage';
import AuthGuard from '../shared/guards/AuthGuard';
import GuestGuard from '../shared/guards/GuestGuard';
import LoginPage from '../pages/loginPage';
import NotFoundPage from '../pages/notFoundPage';
import { RouteIdEnum } from '../core/enums/routes.enum';
import SignUpPage from '../pages/signUpPage';
import ForgotPasswordPage from '../pages/forgotPasswordPage';
import ResetPassword from '../components/resetPassword';
import RolePage from '../pages/rolePage';
import CategoriesListPage from '../pages/categoriesListPage';
import CompaniesPage from '../pages/companiesListPage';
import CreateUserPage from '../pages/createUserPage';
import UsersListPage from '../pages/usersListPage';
import UpdateUserPage from '../pages/updateUserPage';
import UpdateProfilePage from '../pages/updateProfilePage';
import PublicRequestsPage from '../pages/publicRequestsPage';
import CreatePublicReqPage from '../pages/createPublicReqPage';
import UpdatePublicReqPage from '../pages/updatePublicReqPage';
import RequestDetailsPage from '../pages/requestDetailsPage';
import ChangePasswordPage from '../pages/changePasswordPage';
import ProfilePage from '../pages/profilePage';
import ActionHistoryPage from '../pages/actionHistoryPage';
import PendingRequestsPage from '../pages/pendingRequestsPage';
import FailedRequestsPage from '../pages/failedRequestsPage';
import UnassignedRequestsPage from '../pages/unassignedReqPage';
import MyAssignedRequestsPage from '../pages/myAssignedRequestsPage';
import LinkedInRequestsPage from '../pages/linkedInRequestsPage';
import ScrapeLinkedInPage from '../pages/scrapeLinkedInPage';
import DemoPage from '../pages/demoPage';
import { GeneralStatsPage } from '../pages/generalStatsPage';
import AdminDashboardPage from '../pages/adminDashboardPage';
import TechnicianDashboardPage from '../pages/technicianDashboardPage';

const RoutesConfig: RouteObject[] = [
  {
    path: RouteIdEnum.Home,
    element: (
      <AuthGuard>
        <HomePage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.Login,
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    ),
  },
  {
    path: RouteIdEnum.ForgotPassword,
    element: (
      <GuestGuard>
        <ForgotPasswordPage />
      </GuestGuard>
    ),
  },
  {
    path: RouteIdEnum.ResetPassword,
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  }, 
  {
    path: RouteIdEnum.SignUp,
    element: (
      <GuestGuard>
        <SignUpPage />
      </GuestGuard>
    ),
  },
  {
    path: RouteIdEnum.demo,
    element: (
      <GuestGuard>
        <DemoPage />
      </GuestGuard>
    ),
  },
  {
    path: RouteIdEnum.Root,
    element: (
      <GuestGuard>
        <HomePage />
      </GuestGuard>
    ),
  },
  {
    path: RouteIdEnum.Any,
    element: <NotFoundPage />,
  },
  {
    path: RouteIdEnum.Roles,
    element: (
      <AuthGuard>
        <RolePage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.Companies,
    element: (
      <AuthGuard>
        <CompaniesPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.Categories,
    element: (
      <AuthGuard>
        <CategoriesListPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.UsersList,
    element: (
      <AuthGuard>
        <UsersListPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.CreateUser,
    element: (
      <AuthGuard>
        <CreateUserPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.UpdateUser,
    element: (
      <AuthGuard>
        <UpdateUserPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.RequestsList,
    element: (
      <AuthGuard>
        <PublicRequestsPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.CreateRequest,
    element: (
      <AuthGuard>
        <CreatePublicReqPage />
      </AuthGuard>
    ),
  },

  {
    path: RouteIdEnum.UpdateProfile,
    element: (
      <AuthGuard>
        <UpdateProfilePage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.UpdateRequest,
    element: (
      <AuthGuard>
        <UpdatePublicReqPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.RequestDetails,
    element: (
      <AuthGuard>
        <RequestDetailsPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.ChangePassword,
    element: (
      <AuthGuard>
        <ChangePasswordPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.Profile,
    element: (
      <AuthGuard>
        <ProfilePage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.ActionHistory,
    element: (
      <AuthGuard>
        <ActionHistoryPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.PendingRequests,
    element: (
      <AuthGuard>
        <PendingRequestsPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.FailedRequests,
    element: (
      <AuthGuard>
        <FailedRequestsPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.UnassignededRequests,
    element: (
      <AuthGuard>
        <UnassignedRequestsPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.assignedToMe,
    element: (
      <AuthGuard>
        <MyAssignedRequestsPage />
      </AuthGuard>
    ),
  }, 
  {
    path: RouteIdEnum.linkedInRequests,
    element: (
      <AuthGuard>
        <LinkedInRequestsPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.scrapeLinkedIn,
    element: (
      <AuthGuard>
        <ScrapeLinkedInPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.stats,
    element: (
      <AuthGuard>
        <GeneralStatsPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.adminDashboard,
    element: (
      <AuthGuard>
        <AdminDashboardPage />
      </AuthGuard>
    ),
  },
  {
    path: RouteIdEnum.technicianDashboard,
    element: (
      <AuthGuard>
        <TechnicianDashboardPage />
      </AuthGuard>
    ),
  },
];

export default RoutesConfig;
