import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import WebscrapingImage from "../assets/webscraping.jpg"
import { custom } from "../shared/theme/theme";
import { useLoggedIn } from "../hooks/authHooks";

export default function Home() {
  const isLoggedIn = useLoggedIn();
  return (
    <Container component="main" >
      <br/>
      <br/>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        style={{ flex: "1" }}
        color={"white"}
      >
        Web Scraping & Data Analytics
        
      </Typography>
        <br/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box   p={2}>
            <Typography variant="h3" color={"error.main"} component="div">
              Get your keys to your own data mine
            </Typography>
            <br/>
            <Typography fontFamily={"monospace"} component="div"  fontSize={21}   fontWeight={500}>
              Hundreds of pages? Thousands of data points? No worries. With our powerful APIs and skilled experts,
              we supply the web data you need to fuel your business.
            </Typography>
          </Box>
          <Box  p={2}>
            { !isLoggedIn && (
              <>
              <Button href="/signup" variant="outlined" sx={{ color:"success.main",border: `2px solid ${custom.paleBlue}`, marginTop:1}}>signup</Button>
              <Button href="/login" variant="outlined" sx={{  color:"success.main", marginLeft:2, border: `2px solid ${custom.paleBlue}`, marginTop:1}}>login</Button>
              </>
            )

            }
              <Button href="/demo" variant="outlined" sx={{  color:"success.main", marginLeft:2, border: `2px solid ${custom.paleBlue}`, marginTop:1}}>watch demo</Button>
           
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <img  src={WebscrapingImage} width={390} height={370} alt="Web Scraping"/>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item >
          <Box   p={5}>
            <Typography variant="h3" color={"warning.main"} component="div">
            Web Data extraction is complicated!
            </Typography>
            <br/>
            <Typography fontFamily={"monospace"} component="div"  fontSize={21}   fontWeight={500}>
            Your time is better spent analysing the data than collecting it.
            And the best part is that we have our experts at your service!

            </Typography>
          </Box>
        </Grid>
        
      </Grid>
      <Grid container spacing={6}>
        <Grid item >
          <Box   p={5}>
          <Typography variant="h3" color={"success.main"} component="div">
            Our experts at your service
              </Typography>
              <br/>
              <Typography fontFamily={"monospace"} component="div"  fontSize={21}   fontWeight={500}>
              You don't have technical knowledge? You don't have enough time?
              Focus on your business, we’ve got the web data covered
              </Typography>
          </Box>
        </Grid>
        </Grid>
    </Container>
  );
}
