import { Box,  Container} from "@mui/material";
import UpdateProfile from "../components/updateProfile";

export default function UpdateProfilePage() {
  return (
    <Box>
      <Container>
        <br />

        <UpdateProfile />
      </Container>
    </Box>
  );
}
