import { useState, useRef, useEffect, ChangeEvent } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Category } from "../../core/models/types";
import IconButton from "@mui/material/IconButton";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from "../../redux/api/categoryApi";
import Column from "./category.type";
import { custom } from "../../shared/theme/theme";

const columns: readonly Column[] = [
  { id: "name", label: "Tag",
  header:"success"
  // minWidth: 100 
  },
  {
    id: "nbrequests",
    label: "Number of Requests",
    //minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    //minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

function createData(
  id: String,
  name: String,
  nbrequests: Number,
  action: String
) {
  return {
    id,
    name,
    nbrequests,
    action: (
      <>
        <IconButton aria-label="delete">
          <DeleteForeverSharpIcon />
        </IconButton>
        <IconButton aria-label="edit">
          <BorderColorIcon />
        </IconButton>
      </>
    ),
  };
}

export default function CategoriesList() {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { register, handleSubmit, reset } = useForm({ mode: "onBlur" });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const {
    data: category,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetCategoriesQuery();
  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
  const [editingRowId, setEditingRowId] = useState(null);
  const [create] = useCreateCategoryMutation();
  const [deleteCat] = useDeleteCategoryMutation();
  const [updateCat] = useUpdateCategoryMutation();
  const textFieldRef = useRef<HTMLInputElement>(null);
  // Use useEffect to set focus on the text field when it becomes visible
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [editingRowId]);
  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div>;
  }
console.log(category)
  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!category) {
    return <div>No Categories ! </div>;
  }
  let rows: any = [];
  
  category.map((cat: Category) =>{
    rows.push(createData(cat.id, cat.name, cat.requests?.length, ""))
    }
  );
  rows.sort((a: any, b: any) => a.name.localeCompare(b.name));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdateCategoryName = async (id: string, name: string) => {
    const response: any = await updateCat({ id, name });
    setEditingRowId(null); // done editing, reset state
    if (response.error) {
      setErr({ status: true, msg: response.error.data });
    }
    if (response.data) {
      setSuccess({ msg: "Category updated successfully!", status: true });
    }
  };

  const handleDelete = async (id: string) => {
    const response: any = await deleteCat(id);
    if (response.data) {
      setSuccess({ status: true, msg: "category deleted successfully!" });
    } else if (response.error) {
      setErr({ status: true, msg: response.error.data });
    }
  };

  const toggleDelete = (id: string) => {
    setCategoryToDelete(id);

    setIsDeleteDialogOpen(true);
  };

  const onSubmit = async (data: any) => {
   
    setIsButtonDisabled(true);
    const response: any = await create(data.catName);
    if (response.data) {
      setSuccess({ status: true, msg: "Category created successfuly!" });
      refetch();
      reset();
    } else if (response.error) {
      setErr(response.error.data);
    }
  };
  const handleInputChange = (event: any) => {
    setIsButtonDisabled(event.target.value === "");
  };
  const filteredRows = rows.filter((row: any) =>
    row.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  function cancelEdit() {
    setEditingRowId(null);
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", ml: 2 }}>
          <SearchIcon sx={{ color: "secondary", mr: 1, my: 0.5 }} />
          <TextField
            id="search"
            value={searchValue}
            label="Search"
            variant="standard"
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
          />
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ ml: 1, mr: 2 }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              id="catName"
              label="Add a new category"
              variant="standard"
              {...register("catName")}
              onChange={handleInputChange}
            />
            <IconButton
              type="submit"
              disabled={isButtonDisabled}
              color="success"
              
            >
              <AddCircleIcon fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <br />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              await handleDelete(categoryToDelete);
              setIsDeleteDialogOpen(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {err.status && <Alert severity="error">{err.msg}</Alert>}
        {success.status && <Alert severity="success">{success.msg}</Alert>}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead  >
              <TableRow className="table-header">
                {columns.map((column) => (
                  <TableCell  className="table-header"
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchValue
                ? filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      const isEditing = editingRowId === row.id;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update Category">
                                  <IconButton
                                    color="warning"
                                    aria-label="update"
                                    component="span"
                                    onClick={() => setEditingRowId(row.id)}
                                    disabled={isEditing} // prevent clicking while editing
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Category">
                                  <IconButton
                                    color="error"
                                    aria-label="delete"
                                    component="span"
                                    onClick={() => toggleDelete(row.id)}
                                    disabled={isEditing} // prevent clicking while editing
                                  >
                                    <DeleteForeverSharpIcon />
                                  </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "name") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {isEditing ? (
                                    // show text field when editing
                                    <TextField
                                      fullWidth
                                      inputRef={textFieldRef} // Set the ref to the text field
                                      variant="standard"
                                      defaultValue={value}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleUpdateCategoryName(
                                            row.id,
                                            (e.target as HTMLInputElement).value
                                          );
                                        }
                                      }}
                                      onBlur={cancelEdit} // add a onBlur event listener to cancel editing when the user clicks outside of the text field
                                    />
                                  ) : (
                                    // show category name when not editing
                                    value
                                  )}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })
                : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      const isEditing = editingRowId === row.id;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update Category">
                                  <IconButton
                                    color="warning"
                                    aria-label="update"
                                    component="span"
                                    onClick={() => setEditingRowId(row.id)}
                                    disabled={isEditing} // prevent clicking while editing
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Category">
                                  <IconButton
                                    color="error"
                                    aria-label="delete"
                                    component="span"
                                    onClick={() => toggleDelete(row.id)}
                                    disabled={isEditing} // prevent clicking while editing
                                  >
                                    <DeleteForeverSharpIcon />
                                  </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "name") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {isEditing ? (
                                    // show text field when editing
                                    <TextField
                                      fullWidth
                                      inputRef={textFieldRef} // Set the ref to the text field
                                      variant="standard"
                                      defaultValue={value}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleUpdateCategoryName(
                                            row.id,
                                            (e.target as HTMLInputElement).value
                                          );
                                        }
                                      }}
                                      onBlur={cancelEdit} // add a onBlur event listener to cancel editing when the user clicks outside of the text field
                                    />
                                  ) : (
                                    // show category name when not editing
                                    value
                                  )}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
