import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import { Typography } from '@mui/material';

interface ExperienceProps {
  experiences: {
    jobTitle: string;
    company: string;
    date: string;
    skills: string;
  }[];
}

const LinkedInExperienceComponent: React.FC<ExperienceProps> = ({ experiences }) => {
  return (
    <div style={{ textAlign: 'justify' }}>
        <br/>
      <Typography variant="h5" color="error" style={{ fontWeight: 'bold' }}>  <WorkIcon/> Experiences</Typography>
      {experiences.map((experience, index) => (
        <div key={index} >
          <Typography color="primary" style={{ fontWeight: 'bold' }}>{experience.jobTitle}</Typography>
          <Typography  color="black" style={{ fontWeight: 'bold' }}>{experience.company}</Typography>
          <Typography color="grey">{experience.date}</Typography>
          <p>{experience.skills}</p>
        </div>
      ))}
    </div>
  );
};

export default LinkedInExperienceComponent;
