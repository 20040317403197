import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const exchangeRateApi = createApi({
  reducerPath: 'exchangeRateApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://v6.exchangerate-api.com/v6/44478208d3723b4167334285/' }),
  tagTypes: ['exchange'],
  endpoints: (builder) => ({
    getExchangeRates: builder.query<any, void>({
      query: () => 'latest/TND',
      providesTags: ['exchange'],
    }),
  }),
});

export const { useGetExchangeRatesQuery } = exchangeRateApi;
