import { Breadcrumbs, Container, Link, Typography } from "@mui/material";
import ScrapeLinkedIn from "../components/scrapeLinkedin";

import ListIcon from "@mui/icons-material/List";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from "react-router-dom";
export default function ScrapeLinkedInPage(){
    let navigate = useNavigate();
    return(
        <>
        <Container>
        <br/>
        <Breadcrumbs aria-label="breadcrumb" separator="›"> userslist
                <Link underline="hover" color="inherit"  onClick={()=>navigate("/linkedInRequests")}>
                    <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    My LinkedIn Requests
                </Link>
                <Typography >
                    <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Scrape LinkedIn
                </Typography>
            </Breadcrumbs> 
        <ScrapeLinkedIn/>
        </Container>
        </>
        
    )
}