import { AppBar, Toolbar, Typography, Skeleton } from '@mui/material';
import { useGetExchangeRatesQuery } from '../../redux/api/exchangeRateApi';
import ReactCountryFlag from 'react-country-flag';
import "./exchangeRate.css"


export default function ExchangeRates() {
  const { data, isLoading, isError} = useGetExchangeRatesQuery();
  const rates : any= [];
if(data){
    Object.entries(data.conversion_rates).forEach(([currency, rate]) => {

        if ( currency === "DZD") {
          rates.push({  countryCode: "DZ", currency:  currency, rate:  rate, country:"Algeria" });
        } else if ( currency === "SAR") {
          rates.push({  countryCode: "SA", currency:  currency, rate:  rate, country:"Saudi Arabia" });
        } else if ( currency === "CAD") {
          rates.push({  countryCode: "CA", currency:  currency, rate:  rate, country:"Canada" });
        }
        else if ( currency === "DKK") {
          rates.push({  countryCode: "DK", currency:  currency, rate:  rate, country:"Denmark" });
        } else if ( currency === "USD") {
          rates.push({  countryCode: "US", currency:  currency, rate:  rate, country:"United States" });
        } else if ( currency === "GBP") {
          rates.push({  countryCode: "GB", currency:  currency, rate:  rate, country:"United Kingdom" });
        }
        else if ( currency === "JPY") {
          rates.push({  countryCode: "JP", currency:  currency, rate:  rate, country:"Japan" });
        } else if ( currency === "MAD") {
          rates.push({  countryCode: "MA", currency:  currency, rate:  rate, country:"Morocco" });
        }
        else if ( currency === "NOK") {
          rates.push({  countryCode: "NO", currency:  currency, rate:  rate, country:"Norway" });
        } else if ( currency === "SEK") {
          rates.push({  countryCode: "SE", currency:  currency, rate:  rate, country:"Sweden" });
        } else if ( currency === "CHF") {
          rates.push({  countryCode: "CH", currency:  currency, rate:  rate, country:"Switzerland" });
        }
        else if ( currency === "KWD") {
          rates.push({  countryCode: "KW", currency:  currency, rate:  rate, country:"Kuwait" });
        }
        else if ( currency === "AED") {
          rates.push({  countryCode: "AE", currency:  currency, rate:  rate, country:"United Arab Emirates" });
        } else if ( currency === "EUR") {
          rates.push({  countryCode: "EU", currency:  currency, rate:  rate, country:"European Union" });
        } else if ( currency === "LYD") {
          rates.push({  countryCode: "LY", currency:  currency, rate:  rate, country:"Libya" });
        } else if ( currency === "MRU") {
          rates.push({  countryCode: "MR", currency:  currency, rate:  rate, country:"Mauritania" });
        } else if ( currency === "BHD") {
          rates.push({  countryCode: "BH", currency:  currency, rate:  rate, country:"Bahrain" });
        } else if ( currency === "QA") {
          rates.push({  countryCode: "CN", currency:  currency, rate:  rate, country:"China" });
        }
      });
}
const duplicatedRates = [...rates, ...rates];
  if (isLoading) {
    return (
      <AppBar position="fixed">
        <Toolbar>
          <Skeleton variant="text" width={100} height={20} />
        </Toolbar>
      </AppBar>
    );
  }

  if (isError || !data) {
    return <div>Error occurred while fetching data. Please try again.</div>;
  }
  if (rates.length === 0) {
    return (
      <AppBar position="fixed">
        <Toolbar>
          <Skeleton variant="text" width={100} height={20} />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <div>
      <Typography><b>TODAY'S RATE:</b> 1 TND equals to</Typography>
      <div className="horizontal-toolbar-container">
        <Toolbar className="horizontal-toolbar">
          <div className="scrolling-container">
            {duplicatedRates.map((item, index) => (
              <div key={index}>
                <ReactCountryFlag
                  countryCode={item.countryCode}
                  svg
                  style={{ width: '1.5em', height: '1.5em', marginRight: '5px' }}
                />
                <Typography variant="subtitle1">
                  {`${item.currency}: ${item.rate}`}
                </Typography>
              </div>
            ))}
          </div>
        </Toolbar>
      </div>
    </div>
  );
  
}
