import { Box, Paper, Typography } from "@mui/material";
import LinkedInRequests from "../components/requests/linkedInRequests";


export default function LinkedInRequestsPage(){
    return(
        <Box>
                <br/>
            
            <Paper elevation={3} sx={{ my: { xs: 2, md: 1 }, p: { xs: 2, md: 3 } , maxWidth:"1250px", margin: 'auto' }}>
            <Typography component="h2" variant="h2" align="center">
                LinkedIn Requests
            </Typography>
                <LinkedInRequests/>
            </Paper>
            
        </Box>
        
    )
}