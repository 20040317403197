import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import {
  useGetCountMyAssignedRequestsQuery,
  useGetCountMyRequestsByStateQuery,
  useGetCountUnassignedRequestsQuery,
} from "../redux/api/requestsApi";
import Loader from "./Loader/Loader";
import pendingImage from "../assets/dasboard/pendingImage.png";
import assignment from "../assets/dasboard/assignment.png";
import theme from "../shared/theme/theme";
import { Bar } from "react-chartjs-2";
//import randomColor from "randomcolor";

import { useMediaQuery } from '@mui/material';

export default function TechnicianDashboard() {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const {
    data: unassignedRequests,
    isLoading: isUnassignedRequestsLoading,
    isFetching: isUnassignedRequestsFetching,
  } = useGetCountUnassignedRequestsQuery();

  const {
    data: countMyRequestsByState,
    isLoading: isCountMyRequestsByStateLoading,
    isFetching: isGetCountMyRequestsByStateFetching,
  } = useGetCountMyRequestsByStateQuery();

  const {
    data: countMyAssignedRequests,
    isLoading: isCountMyAssignedRequestsLoading,
    isFetching: isCountMyAssignedRequestsFetching,
  } = useGetCountMyAssignedRequestsQuery();
  const cardData = [
    {
      title: "My Assigned Requests",
      value: countMyAssignedRequests,
      image: assignment,
    },
    {
      title: "Unassigned Requests",
      value: unassignedRequests,
      image: pendingImage,
    },
  ];

  const state: any =
    countMyRequestsByState?.map((item: any) => item.state) || [];
  const stateOcc: any =
    countMyRequestsByState?.map((item: any) => item._count.state) || [];
  // Generate dynamic colours
  // const coloursSectorCount = randomColor({ count: state.length });

  /** Bar data */
  const reqBarData = {
    datasets: [
      {
        label: state[0],
        data: [stateOcc[0]],
        backgroundColor: theme.palette.warning.main,
      },
      {
        label: state[1],
        data: [stateOcc[1]],
        backgroundColor: theme.palette.info.main,
      },
      {
        label: state[2],
        data: [stateOcc[2]],
        backgroundColor: theme.palette.secondary.main,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      ],
    },
    legend: {
      position: "right",
      labels:{
        boxWidth:60,

      }
    },
  } as any;
  if (
    isUnassignedRequestsLoading ||
    isUnassignedRequestsFetching ||
    isCountMyRequestsByStateLoading ||
    isGetCountMyRequestsByStateFetching ||
    isCountMyAssignedRequestsLoading ||
    isCountMyAssignedRequestsFetching
  ) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
    <Container sx={{ justifyContent: "center"}}>
      <Box sx={{ display: isSmallScreen ? 'block' :'flex' , gap: 10 }}>
        {cardData?.map((item: any, index: any) => (
          <Card sx={{ display: 'flex', alignItems: 'center', width: isSmallScreen ? '100%' : 500, my: 4, minHeight: isSmallScreen ? 'auto' : 180 }}>
          <CardMedia
            component="img"
            sx={{ width: '30%', height:'80%', margin: 2 }}
            image={item.image}
            alt={item.title.toLowerCase()}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent>
              <br />
              <Typography color="primary.main" variant="h6" fontWeight="bold">
                {item.title}
              </Typography>
              <br />
              <Typography variant="h6" component="p" fontWeight="bold" color="primary.main">
                {item.value}
              </Typography>
            </CardContent>
          </Box>
        </Card>
        
        ))}
      </Box>
      <Paper
          sx={{
            width: '100%',
            height: isSmallScreen ? 'auto' : 400,
           // minHeight: 500,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="primary"
              fontWeight={500}
            >
              My Requests State
            </Typography>
            <br />
            <Box sx={{ height: '100%' }}>
    <Bar data={reqBarData} options={{ ...options, maintainAspectRatio: false }} />
  </Box>

        </Paper>

      </Container>
    </>
  );
}
