import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useState, Fragment } from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import { RouteIdEnum } from "../core/enums/routes.enum";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { logOut, selectCurrentUser } from "../redux/slices/authSlice";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { getUserFromLocalStorage } from "../core/services/localStorage.service";

type Anchor = "left";
export default function CompanyRepMenu() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const user = useAppSelector(selectCurrentUser);
let myId: any
  if(user){
    myId=user.id
  }else{
    myId=getUserFromLocalStorage()
  }
  const [state, setState] = useState({
    left: false,
  });

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "left" ? 250 : undefined }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <br />
      <br />
      <List>
      <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.stats)}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={"General Statistics"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.Profile)}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.RequestsList)}>
            <ListItemIcon>
            <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={"Scraping Requests"} />
          </ListItemButton>
        </ListItem>

        
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate(RouteIdEnum.linkedInRequests)}>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <ListItemText primary={"LinkedIn Scraper"} />
          </ListItemButton>
        </ListItem>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation(); // add this line
            handleClick();
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Manage Account" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => navigate("updateprofile/" + myId)}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={"Update Profile"} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate(RouteIdEnum.ChangePassword)}
            >
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Update Password" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              dispatch(logOut());
              navigate(RouteIdEnum.Root);
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <Fragment>
        <IconButton
          onClick={toggleDrawer("left", true)}
          sx={{ color: "white", marginLeft: "1rem" }}
        >
          <MenuIcon sx={{ fontSize: 32 }} />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </Fragment>
    </>
  );
}
