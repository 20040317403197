import {  Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import UnassignedRequestsList from "../components/requests/unassignedReq";

export default function UnassignedRequestsPage() {
  return (
    <Container component="main">
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
      <Typography component="h2" variant="h2" align="center">
            Unassigned Requests
        </Typography>
        <UnassignedRequestsList/>
      </Paper>
    </Container>
  );
}
