import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import src from "../assets/videos/Demo.mp4";

export default function Demo() {
  
  return (
    <div>
      <Container component="main" >
      <div>
        <br/>
        <Typography variant="h3" color={"success.main"} component="div">
          Watch Demo
        </Typography>
        <br/>
      <video src={src} controls width="100%" height="100%" />
    </div>
      </Container>
    </div>
  );
}
