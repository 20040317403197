import { Fragment } from "react";
import { useGetDataQuery } from "../redux/api/dataApi";
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import DataTable from 'react-data-table-component';
import { Box, Button, Container, Skeleton, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import dataTableTheme from '../shared/theme/dataTableTheme';

export default function ScrapedData({ id }: { id?: string }) {
  const sortIcon = <ArrowDownward />;
  const { data: scrapedData } = useGetDataQuery(id ?? "");
 
  if (!scrapedData) {
    return <div><div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div></div>;
  }
  
  const handleDownloadJson = () => { 
    
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(scrapedData.data)], {
      type: "application/json",
    }); 
    element.href = URL.createObjectURL(file);
    element.download = "scrapedData.json";
    document.body.appendChild(element); 
    element.click();
  };
  const extractCols = (data:any, index: any) =>{
    let keys : any =[]
    if(data[index]?.rowData){
      keys = Object.keys(data[index]?.rowData);
      if(data[index]?.title){
        keys.unshift(' ');
      }
    } else {
      if(data){
        keys = Object.keys(data[0]);
      }
      
    }
      return keys;
  }
  const columns = (data:any, index: any) => {
    const cols = extractCols(data, index);
    let rows: any = [];
    cols.map((col: any) => {
      rows.push({
        name: <div><h2>{col.toUpperCase()}</h2></div>,
        selector: (row: any) => row[col],
        sortable: true,   
        style: {
          wordWrap: "break-word",
          overflowWrap: "break-word",
        },
      });
    });
    return rows;
  };
  
  const extractRowsData= (scrapedData:any) =>{
    let data: any =[] ;
    let index = 0;
    if(scrapedData[0]?.tabTitle){
      index = index + 1
    }
    if(scrapedData[0]?.Unit || scrapedData[1]?.Unit){
      index = index + 1
    }
    let row, row2, title ;
    if(scrapedData[index]?.rowData){
      scrapedData.map((item: any) =>{  
        if(item.rowData && item.title) {
          row = item.rowData;
          title=item.title
          row2= { " ":title, ...row}
          //row.title = item.title
          data.push(row2)
        } else if(item.rowData){
          data.push(item.rowData)
        }
          
      });
    } else {
      data = scrapedData
    }
    return data;   
  }

  const renderData = () => {

    if(Array.isArray(scrapedData.data[0])){
    const tableComponents = scrapedData.data[0].map((data: any) => {
      //if(Array.isArray(data)){
        let index = 0;
        let title = "";
        let unit ="";
        if(data[0]?.tabTitle){ 
          index = index + 1
          title=data[0]?.tabTitle
        }
        if(data[0]?.Unit ){
          index = index + 1
          unit=data[0]?.Unit 
        }
        if(data[1]?.Unit ){
          index = index + 1
          unit=data[1]?.Unit 
        }
        

        return (
          <div >
            <div style={{ display: 'flex', alignItems: 'left' }}>
            {unit && <h5 color="primary">{unit}</h5>} 
            </div>
            <DataTable
              columns={columns(data, index)}
              data={extractRowsData(data)}
              //data={columns(data, index)}
              customStyles={dataTableTheme}
              pagination
              sortIcon={sortIcon}
              noTableHead={false}
              striped
              highlightOnHover  
            title={title || undefined}
            responsive={true}
            />
            
           
          </div>
        );
    });
    return <>{tableComponents}</>; } 
      else{
      return (
        <div>
          <DataTable
            columns={columns(scrapedData?.data, 0)}
            data={extractRowsData(scrapedData?.data)}
            pagination
            sortIcon={sortIcon}
            noTableHead={false}
            striped
            highlightOnHover
            customStyles={dataTableTheme}
            responsive={true}
           // title={title || undefined}
          />
        </div>
      );
    }
  };
  //console.log("scrapedData.data",scrapedData.data[0])
  const handleDownloadCsv = () => {
    const zip = new JSZip();
    let tabTitle = "data";
    if(scrapedData.data[0][1]){
      let i = 0;
    for (let table in scrapedData.data[0]) {
      let tabTitle = "data"+i;
      let index = 0;
      let rows = scrapedData.data[0][table];
      //let csvContent = "data:text/csv;charset=utf-8,";
      let csvContent = "";
      if (rows[index]?.tabTitle) {
        tabTitle = rows[index]?.tabTitle;
        index = index + 1;
      }
      if (rows[index]?.Unit) {
        index++;
      }
      // add the headers
      if (rows[index]?.title && rows[index]?.rowData) {
        let headers = " ," + Object.keys(rows[index].rowData).join(",");
        csvContent += headers + "\r\n";
      }else if(rows[index].rowData){
        let headers = Object.keys(rows[index].rowData).join(",");
        csvContent += headers + "\r\n";
      }
  
      // add the rows
      rows?.forEach((row:any) => {
        let values
        if (!row?.Unit && !row?.tabTitle) {
          if (row?.title && row?.rowData) {
            values = row.title + ',' + Object.values(row.rowData).join(",");
            csvContent += values + "\r\n";
          } else {
            values = Object.values(row.rowData).join(",");
            csvContent += values + "\r\n";
          }
        }
      });
  
      // add the CSV file to the zip archive
      zip.file(tabTitle + ".csv", csvContent);
      i ++;
    }
  
    // create a download link for the zip file
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "data.zip";
      link.click();
    });

  } else // if(scrapedData.data[0][0][0]?.rowData || scrapedData.data[0][0][1]?.rowData || scrapedData.data[0][0][2]?.rowData ){
    if (
      scrapedData?.data?.[0]?.[0]?.[0]?.rowData ||
      scrapedData?.data?.[0]?.[0]?.[1]?.rowData ||
      scrapedData?.data?.[0]?.[0]?.[2]?.rowData
    ){
    let index = 0;
    let csvContent = "";
    if (scrapedData.data[0][0][0]?.tabTitle) {
      tabTitle = scrapedData.data[0][0][0]?.tabTitle;
      index = index + 1;
    }
    if (scrapedData.data[0][0][index]?.Unit) {
      index++;
    }
    // add the headers
    if (scrapedData.data[0][0][index]?.title && scrapedData.data[0][0][index]?.rowData) {
      let headers = " ," + Object.keys(scrapedData.data[0][0][index].rowData).join(",");
      csvContent += headers + "\r\n";
    } else if (scrapedData.data[0][0][index]?.rowData) {
      let headers = Object.keys(scrapedData.data[0][0][index].rowData).join(",");
      csvContent += headers + "\r\n";
    }

    // add the rows
    scrapedData.data[0][0]?.forEach((row:any) => {
      let values
      if (!row?.Unit && !row?.tabTitle) {
        if (row?.title && row?.rowData) {
          values = row.title + ',' + Object.values(row.rowData).join(",");
          csvContent += values + "\r\n";
        } else {
          values = Object.values(row.rowData).join(",");
          csvContent += values + "\r\n";
        }
      }
    });
      const blob = new Blob([csvContent], { type: 'text/csv' });

          // Create URL for Blob object
          const url = URL.createObjectURL(blob);

          // Create anchor element
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = tabTitle;

          // Trigger download
          anchor.click();
  } else {
      let csvString =""
      let csvString2 =""
      let keys = Object.keys(scrapedData?.data[0]).join(',');
      scrapedData?.data.forEach((item: any) => {
        const values = Object.values(item);
        const row = values.map((value: any) => `"${value}"`).join(",");
        csvString += row + "\n";
      });
      csvString2= keys +'\n'+csvString; // Remove quotes;
      // Create Blob object from CSV string
          const blob = new Blob([csvString2], { type: 'text/csv' });

          // Create URL for Blob object
          const url = URL.createObjectURL(blob);

          // Create anchor element
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'data.csv';

          // Trigger download
          anchor.click();
    }

    
    };
  
  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography align="left" variant="h5" sx={{ ml: 2 }}>
          Generated data
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={handleDownloadJson} color="info">
            <DownloadIcon /> JSON
          </Button>
          <Button variant="outlined" onClick={handleDownloadCsv} sx={{ ml: 1, mr:1 }} color="info">
            <DownloadIcon /> CSV
          </Button>
        </Box>
      </Box>
      <Container>
        {renderData()}
      </Container>
    </Fragment>
  );
}
