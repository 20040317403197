import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Request } from "../../core/models/types";
import IconButton from "@mui/material/IconButton";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  Container,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  useGetRequestsByUserQuery,
  useDeleteRequestMutation,
} from "../../redux/api/requestsApi";
import Column from "./requests.types";
import { ChangeEvent, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrentUser } from "../../redux/slices/authSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import { getUserFromLocalStorage } from "../../core/services/localStorage.service";
import { custom } from "../../shared/theme/theme";
import Loader from "../Loader/Loader";
const columns: readonly Column[] = [
  { id: "title", label: "Title", maxWidth: 100 },
  { id: "website", label: "Website", maxWidth: 500, minWidth: 100 },
  // { id: "categories", label: "Categories", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 100 },
  { id: "updatedAt", label: "Updated At", minWidth: 100 },

  {
    id: "action",
    label: "Action",
    minWidth: 90,
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

function createData(
  id: String,
  title: String,
  website: String,
  //categories: Category[],
  createdAt: Date,
  updatedAt: Date,
  action: String
) {
  const created: string = moment.utc(createdAt).format("DD-MM-YYYY HH:mm");
  const updated: string = moment.utc(updatedAt).format("DD-MM-YYYY HH:mm");
  return {
    id,
    title,
    website,
    // categories,
    createdAt: created,
    updatedAt: updated,
    action: (
      <>
        <IconButton aria-label="delete">
          <DeleteForeverSharpIcon />
        </IconButton>
        <IconButton aria-label="edit">
          <BorderColorIcon />
        </IconButton>
        <IconButton aria-label="details">
          <InfoOutlinedIcon />
        </IconButton>
      </>
    ),
  };
}

export default function RequestsList() {
  let navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const user = useAppSelector(selectCurrentUser);
  let userId;
  if (user) {
    userId = user.id;
  } else {
    userId = getUserFromLocalStorage();
  }

  const {
    data: request,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetRequestsByUserQuery(userId);

  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
  const [deleteRequest, { isLoading: isDeleteLoading }] =
    useDeleteRequestMutation();
  if (isLoading || isFetching) {
    return (
      <div>
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
      </div>
    );
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!request || request.length === 0) {
    return (
      <div>
        <br />
        <br />
        <br />
        No Requests !
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button variant="contained" onClick={() => navigate("/createrequest")}>
          Create a new Request
        </Button>
      </div>
    );
  }
  let rows: any = [];
  request.map((req: Request) => {
    if (!req.website.googleQuery)
      rows.push(
        createData(
          req.id,
          req.title,
          req.website.url,
          //  req.categories,
          req.createdAt,
          req.updatedAt,
          ""
        )
      );
  });
  rows.sort((a: any, b: any) => a.createdAt?.localeCompare(b.createdAt));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (id: string) => {
    const response: any = await deleteRequest(id);
    if (response.data) {
      setSuccess({ status: true, msg: "request deleted successfully!" });
    } else if (response.error) {
      setErr({ status: true, msg: response.error.data });
    }
  };

  const toggleDelete = (id: string) => {
    setRequestToDelete(id);

    setIsDeleteDialogOpen(true);
  };
  const filteredRows = rows.filter((row: any) =>
    Object.values(row).some(
      (value: any) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  return (
    <Box>
      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", ml: 2 }}>
          <SearchIcon sx={{ mr: 1, my: 0.5 }} />
          <TextField
            id="search"
            value={searchValue}
            label="Search"
            variant="standard"
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{ ml: 1, mr: 2 }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Tooltip title="Create New Request">
              <IconButton
                color="success"
                component="span"
                onClick={() => navigate("/createrequest")}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <br />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        {isDeleteLoading ? (
          <DialogContent sx={{ justifyContent: "center" }}>
            <Box>
              <Typography color={"primary"}>
                Please wait a moment ...
              </Typography>

              <Container sx={{ justifyContent: "center", margin: 2 }}>
                <Loader />
              </Container>
            </Box>
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this request?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await handleDelete(requestToDelete);
                  setIsDeleteDialogOpen(false);
                }}
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Paper
        sx={{ width: "100%", overflow: "hidden", justifyContent: "center" }}
      >
        {err.status && <Alert severity="error">{err.msg}</Alert>}
        {success.status && <Alert severity="success">{success.msg}</Alert>}
        <TableContainer style={{ justifyContent: "center" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ justifyContent: "center" }}
          >
            <TableHead sx={{ justifyContent: "center" }}>
              <TableRow sx={{ justifyContent: "center" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: custom.paleBlue,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      fontWeight: "bold", // add font weight to make the text bold
                      justifyContent: "center",
                    }}
                  >
                    <Container style={{ justifyContent: "center" }}>
                      <b>{column.label}</b>
                    </Container>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchValue
                ? filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update Request">
                                    <IconButton
                                      color="warning"
                                      onClick={() => {
                                        navigate("/updaterequest/" + row.id, {
                                          state: { existingReq: row.id },
                                        });
                                      }}
                                      //onClick={() => setEditingRowId(row.id)}
                                    >
                                      <BorderColorIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Request">
                                    <IconButton
                                      color="error"
                                      aria-label="delete"
                                      component="span"
                                      onClick={() => toggleDelete(row.id)}
                                    >
                                      <DeleteForeverSharpIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Request Details">
                                    <IconButton
                                      aria-label="details"
                                      component="span"
                                      color="info"
                                      onClick={() =>
                                        navigate("/requestDetails/" + row.id)
                                      }
                                    >
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "website") {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    minWidth: column.minWidth,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Link to={value}> {value}</Link>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    minWidth: column.minWidth,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })
                : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update Request">
                                    <IconButton
                                      color="warning"
                                      onClick={() =>
                                        navigate("/updaterequest/" + row.id)
                                      }
                                    >
                                      <BorderColorIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Request">
                                    <IconButton
                                      color="error"
                                      aria-label="delete"
                                      component="span"
                                      onClick={() => toggleDelete(row.id)}
                                    >
                                      <DeleteForeverSharpIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Request Details">
                                    <IconButton
                                      aria-label="details"
                                      component="span"
                                      color="info"
                                      onClick={() =>
                                        navigate("/requestDetails/" + row.id)
                                      }
                                    >
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "website") {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    minWidth: column.minWidth,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Container style={{}}>
                                    <Link to={value}> {value}</Link>
                                  </Container>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    maxWidth: column.maxWidth,
                                    minWidth: column.minWidth,
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
