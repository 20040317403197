import { ReactNode } from 'react';
//import { Navigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

function GuestGuard(props: Props) {
  const { children } = props;

  return <>{children}</>;
}

export default GuestGuard;
