import { Box, Container, Paper, Typography } from "@mui/material";
import CategoriesList from "../components/categories/categoriesList";

export default function CategoriesListPage() {
  return (
    <Box>
      <Container>
        <br />

        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              style={{ flex: "1" }}
            >
              Categories
            </Typography>
          </div>
          <br />
          <CategoriesList />
        </Paper>
      </Container>
    </Box>
  );
}
