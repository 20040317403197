import {  Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function NotFoundPage() {
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography color={"primary"}>NOT FOUND!</Typography>
      </Paper>
    </Container>
  );
}
