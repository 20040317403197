import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";
import UpdateRequest from "../components/requests/updatePubicRequest";
import { useRole } from "../hooks/authHooks";
import UpdatePrivateRequest from "../components/requests/updatePrivateRequest";

export default function UpdatePublicReqPage() {
  let navigate = useNavigate()
  let role = useRole();
  return (
    <Box>
      <Container>
        <br />
        { role === "technician" ?(
                <Breadcrumbs aria-label="breadcrumb" separator="›"> userslist
                <Link underline="hover" color="inherit"  onClick={()=>navigate("/assignedToMe")}>
                    <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    My Assigned Requests
                </Link>
                <Typography >
                    <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Handle Request
                </Typography>
            </Breadcrumbs> 
            ) : (
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link underline="hover" color="inherit"  onClick={()=>navigate("/requestslist")}>
            <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Requests List
          </Link>

          <Typography>
            <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Update Request
          </Typography>
        </Breadcrumbs> ) }

        {role === "company representative" ? (
          <UpdatePrivateRequest/>
        ) : (
          <UpdateRequest />
        )}
        
      </Container>
    </Box>
  );
}
