import { Box, Container, Typography } from "@mui/material";
import UserHome from "../components/profile";


export default function ProfilePage(){
    return(
        <Box>
            <Container>
                <br/>
            
            <Typography component="h3" variant="h3" color={"white"} align="center">
                Welcome Back Data Hunter!
            </Typography>
                <UserHome/>
            </Container>
            
        </Box>
        
    )
}