import { Container, Paper } from "@mui/material";
import { useAssignRequestMutation, useGetRequestQuery} from "../../redux/api/requestsApi";
import ScrapedData from "../scrapedData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { getRoleFromLocalStorage, getUserFromLocalStorage } from "../../core/services/localStorage.service";
import { useState } from "react";
import {Tooltip} from "@mui/material"
import CleanData from "../cleanData";
import ProfileListComponent from "../linkedInProfileList";
import {  Typography  } from "@mui/material";
import Loader from "../Loader/Loader";

export default function RequestDetails(){
    const url = window.location.href;
    const urlParts = url.split("/");
    const id = urlParts[urlParts.length - 1]; // get second-to-last element
    let userId = getUserFromLocalStorage()
    const { data: request, isLoading, isFetching, isError} = useGetRequestQuery(id);
    let dataId = request?.dataId
    let cleanDataId = request?.cleanDataId ? request?.cleanDataId : ""
    let technician
    const [AssignRequest] = useAssignRequestMutation();
  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
    const role = getRoleFromLocalStorage();
    if (isLoading || isFetching) {
        return (
          <div>
            <Loader/>
          </div>
        );
      }

      if (isError) {
        return <Alert severity="error" >Something went wrong!</Alert>;
      }
    if(request?.needTech === true)
        technician = "yes"
    else
        technician="no"

    const handleAssign = async (id : any) =>{
        let response: any = await  AssignRequest(id);
        if(response.data){
            setSuccess({status:true, msg:"Request Assigned Successfully"})
        } else{
            setErr({status:true, msg:response.error.data})
        }
        }
 
    return(
        <>
       {(!request?.website.googleQuery) ? (
        <>
        <Container>

       
        <TableContainer component={Paper}>
        <div style={{ width: '100%' }}>
        {err.status && <Alert severity="error">{err.msg}</Alert>}
    {success.status && <Alert severity="success">{success.msg}</Alert>}

        
    
        </div>
        <Typography component="h2" variant="h2" align="center" >
                Request Details
                { (role === "technician" && request?.idTech !== userId) &&(
        <div style={{float: 'right', marginRight:10}}>
            <Tooltip title="Assign to me">
            <IconButton aria-label="assign"
            component="span"
            color="success"
            onClick={() => handleAssign(id)}  >
              <PlaylistAddIcon fontSize="large" />
            </IconButton>
            </Tooltip>
            </div>
        ) }
            </Typography>
        <br/>

        
        
        <Table sx={{ minWidth: 500 }}>
            <TableBody>
            <TableRow>
                    <TableCell component="th" scope="row">
                       <b>Title</b>
                    </TableCell>
                    <TableCell   align="left">
                       {request?.title}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                       <b>Website</b>
                    </TableCell>
                    <TableCell   align="left">
                       <Link to={request?.website.url}>{request?.website.url}</Link> 
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                       <b>State</b> 
                    </TableCell>
                    <TableCell   align="left">
                        {request?.state}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Type</b>
                    </TableCell>
                    <TableCell   align="left">
                        {request?.type}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Need Technician</b> 
                    </TableCell>
                    <TableCell   align="left">
                        {technician}
                    </TableCell>
                </TableRow>
                {request?.description &&
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Description</b> 
                    </TableCell>
                    <TableCell   align="left">
                        {request?.description}
                    </TableCell>
                </TableRow>
                }
                {request?.categories[0] &&
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Category</b> 
                    </TableCell>
                    <TableCell>
                        {request?.categories.map((val:any)=> (
                        <Chip key={val.name.toString()} label={val.name.toString()} />) 
                        ) }
                    </TableCell>
                </TableRow>
                }
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Creation date</b> 
                    </TableCell>
                    <TableCell>
                        {moment.utc(request?.createdAt).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Last update</b> 
                    </TableCell>
                    <TableCell>
                        {moment.utc(request?.updatedAt).format("DD-MM-YYYY HH:mm")} 
                    </TableCell>
                </TableRow>

                {request?.techMsg &&
                <TableRow>
                    <TableCell component="th" scope="row">
                        <b>Failure Reason</b> 
                    </TableCell>
                    <TableCell>
                        {request?.techMsg}
                    </TableCell>
                </TableRow>
                }
                
            </TableBody>
        </Table>
    </TableContainer>
        {request?.state === "done" && 
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <br/>
            <ScrapedData id={dataId?.toString()} />
            <br/>
            {role === "administrator" && 
            <CleanData  reqId = {id}/> 
            }
            
             
        </Paper>
        }
        
    </Container>
    </>
       ) :( 
       <>
       <Typography component="h2" variant="h2" align="center" color={"white"}>
                Scraped Profiles
            </Typography>
       <Container>
       <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} >
                <ProfileListComponent profiles={request.Data.data} itemsPerPage={1} />
                </Paper>
            </Container>
            </>
        ) } 
        
        </>
    )
    
}