import { Chip, Container } from '@mui/material';
import React from 'react';

type SkillsComponentProps = {
  skills: string[];
};

const LinkedInSkillsComponent: React.FC<SkillsComponentProps> = ({ skills }) => {
  return (
    <div>
      <h2>Skills</h2>
      <Container sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
      {skills.map((skill, index) => (
        <Chip key={index} label={skill} />
      ))}
    </Container>
    </div>
  );
};

export default LinkedInSkillsComponent;
