import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import CreatePublicRequest from "../components/requests/createRequest";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListIcon from "@mui/icons-material/List";
import {  useNavigate } from "react-router-dom";

export default function CreatePublicReqPage() {
  let navigate = useNavigate()
  return (
    <Box>
      <Container>
        <br />
        <Breadcrumbs aria-label="breadcrumb" separator="›"> Requests List
          <Link underline="hover" color="inherit"  onClick={()=>navigate("/requestslist")}>
            <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Requests List
          </Link>

          <Typography>
            <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Create New Request
          </Typography>
        </Breadcrumbs>

        <CreatePublicRequest />
      </Container>
    </Box>
  );
}
