import {useGetCompaniesQuery} from "../redux/api/companyApi";
import { Company } from "../core/models/types";
import { ChangeEvent, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
} from "@mui/material";
import { custom } from "../shared/theme/theme";

function createData(
  id : String,
  name: String,
  address: String,
  email: String,
  phone: String,
) {
  return { id, name, address, email, phone };
}

export default function CompanyComponent() {
  const {
    data: company,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetCompaniesQuery();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div>;
  }

  if (isError) {
    if ('status' in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!company) {
    return <div>No ROLES ! </div>;
  }

  const rows = company.map((comp: Company) =>
    createData(comp.id, comp.name, comp.address, comp.email, comp.phone),
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
 // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}>
              <b>Company</b>
            </TableCell>
            <TableCell style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}>
              <b>Address</b>
            </TableCell>
            <TableCell style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}>
              <b>Email</b>
            </TableCell>
            <TableCell style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}>
              <b>Phone</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row: any) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.address}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}