import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {  Request, User } from "../../core/models/types";
import { Alert, Box, Skeleton } from "@mui/material";
//import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';

import { useGetFailedRequestsQuery} from "../../redux/api/requestsApi";
import  { PendingColumn } from "./requests.types";
import { ChangeEvent, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { custom } from "../../shared/theme/theme";
const columns: readonly PendingColumn[] = [
  { id: "website", label: "Website", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 100 },
  { id: "updatedAt", label: "Updated At", minWidth: 100 },
  { id: "user", label: "User", minWidth: 100 },
  { id: "tech", label: "Technician", minWidth: 100 },
  {
    id: "action",
    label: "Reason",
    minWidth: 100},
];

function createData(
  id: String,
  website: String,
  createdAt: Date,
  updatedAt: Date,
  user: User,
  tech: User,
  action: String
) {
  const created: string = moment.utc(createdAt).format("DD-MM-YYYY HH:mm");
  const updated: string = moment.utc(updatedAt).format("DD-MM-YYYY HH:mm");
  const technician : String = tech?.username;
  const username : String = user.username;
  return {
    id,
    website,
    createdAt: created ,
    updatedAt : updated,
    user: username,
    tech: technician,
    action: action,
  };
}

export default function PendingRequestsList() {
//  let navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: request,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetFailedRequestsQuery();
  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div>;
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!request || request.length === 0) {
    return(<div> 
        <br/><br/><br/>
        No Failed Requests !
        <br/><br/><br/><br/><br/>

        </div>) ;
  }
  let rows: any = [];
  request.map((req: Request) =>
    rows.push(
      createData(
        req.id,
        req.website.url,
        req.createdAt,
        req.updatedAt,
        req.User,
        req.tech,
        req.techMsg,
      )
    ),
    
  );
  rows.sort((a: any, b: any) => -1 * (a.updatedAt?.localeCompare(b.updatedAt)));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = rows.filter((row: any) =>
  Object.values(row).some(
    (value: any) =>
      typeof value === "string" &&
      value.toLowerCase().includes(searchValue.toLowerCase())
  )
);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <br />

      

      <br />
     
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {err.status && <Alert severity="error">{err.msg}</Alert>}
        {success.status && <Alert severity="success">{success.msg}</Alert>}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      minWidth: column.minWidth,
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchValue
                ? filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "categories") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value.map((val:any)=> (
                                    <Chip key={val.name.toString()} label={val.name.toString()} />) 
                                    ) }
                                </TableCell>
                              );
                            } else if(column.id === "website"){
                              return(
                              <TableCell key={column.id} align={column.align}>
                              <Link to= {value}> {value}</Link>
                            </TableCell>)
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })
                : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                             
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "categories") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value.map((val:any)=> (
                                    <Chip key={val.name.toString()} label={val.name.toString()} />) 
                                    ) }
                                </TableCell>
                              );
                            } else if(column.id === "website"){
                                return(
                                <TableCell key={column.id} align={column.align}>
                                <Link to= {value}> {value}</Link>
                              </TableCell>)
                              }else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
