import { Box, Container, Paper, Typography } from "@mui/material";
import CompanyComponent from "../components/companyComponent";


export default function CompaniesPage(){
    return(
        <Box>
            <Container>
                <br/>
            
            <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h2" align="center">
                Companies
            </Typography>
            <hr/>
                <CompanyComponent/>
            </Paper>
            </Container>
            
        </Box>
        
    )
}