import { Paper, Container, Skeleton, Typography } from "@mui/material";
import { Bar } from 'react-chartjs-2';
import theme from "../shared/theme/theme"
import { useGetCleanDataBySubjectQuery } from "../redux/api/dataApi";
import { CONFIG } from "../core/constant/config";

export default function RealEstateChart() {
  const encodedString_Annual_glissement = encodeURIComponent(CONFIG.DASHBOARD.Annual_glissement);
  const encodedString_RealEstatePrices = encodeURIComponent(CONFIG.DASHBOARD.RealEstatePrices); 
  const { data:Annual_glissement, isLoading: isLoading1, isFetching: isFetching1 } = useGetCleanDataBySubjectQuery(encodedString_Annual_glissement);
  const { data:RealEstatePrices, isLoading, isFetching } = useGetCleanDataBySubjectQuery(encodedString_RealEstatePrices);

  if (isLoading || isFetching || isLoading1|| isFetching1) {
    return (
      <div>
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
      </div> 
    );
  }
  let object : any = Annual_glissement?.data[0]
  const firstKey = Object.keys(object)[0];
  const labels = Annual_glissement?.data?.map( (obj: any) => obj[firstKey]);

  const secondKey = Object.keys(object)[1];
  const secondKey_values = Annual_glissement?.data?.map( (obj: any) => obj[secondKey]);

  const thirdKey = Object.keys(object)[2];
  const thirdKey_values = Annual_glissement?.data?.map( (obj: any) => obj[thirdKey]);

  const fourthKey = Object.keys(object)[3];
  const fourthKey_values = Annual_glissement?.data?.map( (obj: any) => obj[fourthKey]);

  
  const data1 = {
    labels: labels,
    datasets: [
      {
        label: secondKey,
        data: secondKey_values,
        backgroundColor: theme.palette.warning.main,
      },
      {
        label: thirdKey,
        data: thirdKey_values,
        backgroundColor: theme.palette.secondary.main,
      },
      {
        label: fourthKey,
        data: fourthKey_values,
        backgroundColor: theme.palette.success.main,
      },
    ],
  };

  let object2 : any = RealEstatePrices?.data[0]
  
  const firstKey2 = Object.keys(object2)[0];
  const labels2 = RealEstatePrices?.data?.map( (obj: any) => obj[firstKey2]);

  const secondKey2 = Object.keys(object2)[1];
  const secondKey_values2 = RealEstatePrices?.data?.map( (obj: any) => obj[secondKey2]);
 //console.log(secondKey_values2)
  const thirdKey2 = Object.keys(object2)[2];
  const thirdKey_values2 = RealEstatePrices?.data?.map( (obj: any) => obj[thirdKey2]);

  const fourthKey2 = Object.keys(object2)[3];
  const fourthKey_values2 = RealEstatePrices?.data?.map( (obj: any) => obj[fourthKey2]);

  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: secondKey2,
        data: secondKey_values2,
        backgroundColor: theme.palette.warning.main,
      },
      {
        label: thirdKey2,
        data: thirdKey_values2,
        backgroundColor: theme.palette.secondary.main,
      },
      {
        label: fourthKey2,
        data: fourthKey_values2,
        backgroundColor: theme.palette.success.main,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: false,
          }
      }]
  }
  } as any;

  return (
    <Container>
      <Paper sx={{ height: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginY:4 }}>
        <Typography component="h2" variant="h5" align="center" color="primary" fontWeight={500} sx={{my:1}}>
          {Annual_glissement.subject}
        </Typography>
        <div style={{ width: '100%', height: '100%' }}>
          <Bar data={data1} options={options} />
        </div>
      </Paper>
      <Paper sx={{ height: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography component="h2" variant="h5" align="center" color="primary" fontWeight={500} sx={{my:1}}>
          {RealEstatePrices.subject}
        </Typography>
        <div style={{ width: '100%', height: '100%' }}>
          <Bar data={data2} options={options} />
        </div>
      </Paper>

    </Container>
  );
}
