import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Role, User } from "../../core/models/types";
import IconButton from "@mui/material/IconButton";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Box, Checkbox, Skeleton, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";

import {
  useGetUsersQuery,
  useDeleteUserMutation,
  useDeleteUsersMutation,
} from "../../redux/api/usersApi";
import Column from "./users.type";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { custom } from "../../shared/theme/theme";

const columns: readonly Column[] = [
  { id: "select", label: "select", minWidth: 50 },
  /* { id: "id", label: "Id", minWidth: 170 }, */
  { id: "username", label: "Username", minWidth: 100 },
  { id: "firstName", label: "First Name", minWidth: 100 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  { id: "tel", label: "Tel", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },

  {
    id: "action",
    label: "Action",
    minWidth: 150,
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

function createData(
  select: String,
  id: String,
  email: String,
  username: String,
  firstName: String,
  lastName: String,
  tel: String,
  Role: Role,
  action: String
) {
  const role = Role.name;
  return {
    select,
    id,
    email,
    username,
    firstName,
    lastName,
    tel,
    role,
    action: (
      <>
        <IconButton aria-label="delete">
          <DeleteForeverSharpIcon />
        </IconButton>
        <IconButton aria-label="edit">
          <BorderColorIcon />
        </IconButton>
        <IconButton aria-label="edit">
          <HistoryIcon />
        </IconButton>
      </>
    ),
  };
}

export default function UsersList() {
  let navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteManyDialogOpen, setIsDeleteManyDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: user,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetUsersQuery();
  const [err, setErr] = useState({ status: false, msg: "" });
  const [success, setSuccess] = useState({ status: false, msg: "" });
  const [editingRowId] = useState(null);
  const [deleteUser] = useDeleteUserMutation();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [deleteUsers] = useDeleteUsersMutation();
  const textFieldRef = useRef<HTMLInputElement>(null);
  // Use useEffect to set focus on the text field when it becomes visible
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [editingRowId]);
  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div>;
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!user) {
    return <div>No Users ! </div>;
  }
  let rows: any = [];
  user.map((usr: User) =>{
    if(usr.Role.name !== "administrator"){
      rows.push(
        createData(
          "",
          usr.id,
          usr.email,
          usr.username,
          usr.firstName,
          usr.lastName,
          usr.tel,
          usr.Role,
          ""
        )
      )
    }
  });
  rows.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (id: string) => {
    const response: any = await deleteUser(id);
    if (response.data) {
      setSuccess({ status: true, msg: "user deleted successfully!" });
    } else if (response.error) {
      setErr({ status: true, msg: response.error.data });
    }
  };

  const toggleDelete = (id: string) => {
    setUserToDelete(id);

    setIsDeleteDialogOpen(true);
  };

  const toggleDeleteMany = (ids: any) => {
    setUserToDelete(ids);

    setIsDeleteManyDialogOpen(true);
  };
  const handleDeleteMany = async (ids: any) => {
    const response: any = await deleteUsers(ids);

    if (response.data) {
      setSuccess({ status: true, msg: "user deleted successfully!" });
      setSelectedRows([]);
    } else if (response.error) {
      setErr({ status: true, msg: response.error.data });
    }
  };
  const filteredRows = rows.filter((row: any) =>
    Object.values(row).some(
      (value: any) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", ml: 2 }}>
          <SearchIcon sx={{ mr: 1, my: 0.5 }} />
          <TextField
            id="search"
            value={searchValue}
            label="Search"
            variant="standard"
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{ ml: 1, mr: 2 }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Tooltip title="Delete Users">
            <IconButton
              color="error"
              disabled={selectedRows.length === 0}
              onClick={() => {
                toggleDeleteMany(selectedRows);
              }}
            >
              <DeleteForeverSharpIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Create User">
            <IconButton
              color= "success"
              onClick={() => navigate("/createuser")}
            >
              <PersonAddAltIcon />
            </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <br />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              await handleDelete(userToDelete);
              setIsDeleteDialogOpen(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteManyDialogOpen}
        onClose={() => setIsDeleteManyDialogOpen(false)}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete these users?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteManyDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await handleDeleteMany(selectedRows);
              setIsDeleteManyDialogOpen(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {err.status && <Alert severity="error">{err.msg}</Alert>}
        {success.status && <Alert severity="success">{success.msg}</Alert>}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: custom.paleBlue, // Change this to the color you want
                      minWidth: column.minWidth,
                      fontWeight: 'bold' // Optional: add font weight to make the text bold
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchValue
                ? filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          selected={selectedRows.includes(row.id)}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update User">
                                  <IconButton
                                    color="warning"
                                    onClick={() =>
                                      navigate("/updateuser/" + row.id)
                                    }
                                    //onClick={() => setEditingRowId(row.id)}
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete User">
                                  <IconButton
                                    color="error"
                                    aria-label="delete"
                                    component="span"
                                    onClick={() => toggleDelete(row.id)}
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <DeleteForeverSharpIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Action History">
                                  <IconButton
                                    color="info"
                                    aria-label="history"
                                    component="span"
                                    onClick={() =>
                                      navigate("/actionHistory/" + row.id)
                                    }
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <HistoryIcon />
                                  </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "select") {
                              return (
                                <TableCell>
                                  <Tooltip title="Select User">
                                  <Checkbox
                                    checked={selectedRows.includes(row.id)}
                                    onChange={(event) =>
                                      event.target.checked
                                        ? setSelectedRows([
                                            ...selectedRows,
                                            row.id,
                                          ])
                                        : setSelectedRows(
                                            selectedRows.filter(
                                              (id) => id !== row.id
                                            )
                                          )
                                    }
                                    disabled={row.role === "administrator"}
                                  />
                                  </Tooltip>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })
                : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          selected={selectedRows.includes(row.id)}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Tooltip title="Update User">
                                  <IconButton
                                    color="warning"
                                    onClick={() =>
                                      navigate("/updateuser/" + row.id)
                                    }
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete User">
                                  <IconButton
                                    color="error"
                                    aria-label="delete"
                                    component="span"
                                    onClick={() => toggleDelete(row.id)}
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <DeleteForeverSharpIcon />
                                  </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Action History">
                                  <IconButton
                                    color="info"
                                    aria-label="history"
                                    component="span"
                                    onClick={() =>
                                      navigate("/actionHistory/" + row.id)
                                    }
                                    disabled={
                                      selectedRows.length !== 0 ||
                                      row.role === "administrator"
                                    }
                                  >
                                    <HistoryIcon />
                                  </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            } else if (column.id === "select") {
                              return (
                                <TableCell>
                                  <Tooltip title="Select User">
                                  <Checkbox
                                    checked={selectedRows.includes(row.id)}
                                    onChange={(event) =>
                                      event.target.checked
                                        ? setSelectedRows([
                                            ...selectedRows,
                                            row.id,
                                          ])
                                        : setSelectedRows(
                                            selectedRows.filter(
                                              (id) => id !== row.id
                                            )
                                          )
                                    }
                                    disabled={row.role === "administrator"}
                                  />
                                  </Tooltip>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
