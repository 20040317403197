import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {roleApi} from './api/roleApi';
import { authApi } from './api/authApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './slices/authSlice'
import { categoryApi } from './api/categoryApi';
import { companyApi } from './api/companyApi';
import { userApi } from './api/usersApi';
import { requestApi } from './api/requestsApi';
import { dataApi } from './api/dataApi';
import { actionHistoryApi } from './api/actionHistoryApi';
import { exchangeRateApi } from './api/exchangeRateApi';

export const store = configureStore({
  reducer: {
    [roleApi.reducerPath]: roleApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [actionHistoryApi.reducerPath]: actionHistoryApi.reducer,
    authApi: authApi.reducer,
    auth : authReducer,
    [exchangeRateApi.reducerPath] : exchangeRateApi.reducer,
    //signUp : signUpReducer
  },
   // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
   middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(authApi.middleware, roleApi.middleware, categoryApi.middleware, companyApi.middleware, userApi.middleware,
      requestApi.middleware, dataApi.middleware, actionHistoryApi.middleware, exchangeRateApi.middleware )    
});
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
