const  customStyles = {
    header: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            //weight: "18rm",
            color : '#FF377D',
            fontSize:"25px",
            fontWeight: 'bold',
        },
    },

    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            color : '#8888bd',
            fontSize:"10px"
        },
    },
    title: {
        style: {
            color : '#FF377D'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            background: '#F0F8FF'
        },
    },

};

export default customStyles;
