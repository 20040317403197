
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { logOut } from '../../redux/slices/authSlice';


import { getRefreshTokenFromLocalStorage ,saveRefreshTokenToLocalStorage, saveAccessTokenToLocalStorage,} from './localStorage.service';
import { LoginResponse } from '../models/types';
import { CONFIG } from '../constant/config';

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: CONFIG.BASE_URL_API,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const customFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = getRefreshTokenFromLocalStorage();
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            credentials: 'include',
            //url: CONFIG.ENDPOINTS.auth.refresh + serviceId,
            url: CONFIG.ENDPOINTS.auth.refresh   ,
            body: { refreshToken: refreshToken },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const resultRefresh: LoginResponse =
            refreshResult.data as LoginResponse;
          if (resultRefresh.accessToken) {
            saveAccessTokenToLocalStorage(resultRefresh.accessToken);
            saveRefreshTokenToLocalStorage(resultRefresh.refreshToken);
          }
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
         // localStorage.clear();

        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
