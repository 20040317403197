import { Alert, Container, Grid, Paper, Skeleton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useGetProfileQuery } from "../redux/api/usersApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import UpdateIcon from "@mui/icons-material/Update";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { getRoleFromLocalStorage } from "../core/services/localStorage.service";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClassIcon from "@mui/icons-material/Class";

export default function Profile() {
  const { data: user, isFetching, isLoading, isError } = useGetProfileQuery();
  //const user = getUserFromLocalStorage()
  const role = getRoleFromLocalStorage();
  if (isLoading || isFetching) {
    return (
      <div>
        <br />
        <Skeleton variant="rectangular" height={30} />
        <br />
        <Skeleton variant="rectangular" height={30} />
        <br />
        <Skeleton variant="rectangular" height={30} />
      </div>
    );
  }

  if (isError) {
    return <Alert severity="error" >Something went wrong!</Alert>;
  }
  const renderProfile = () => {
    return (
      <>
        <br />

        <Typography color={"warning.main"} variant="h5" align="left">
          Your Details:
          <br />
          <br />
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TableCell scope="row" align="center">
                  <MailIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Email</b>
                </TableCell>
                <TableCell align="left">{user?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" scope="row">
                  <AccountCircleIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Username</b>
                </TableCell>
                <TableCell align="left">{user?.username}</TableCell>
              </TableRow>

              {user?.Role.name !== "company representative" && (
                <>
                  <TableRow>
                    <TableCell align="center" scope="row">
                      <BadgeIcon />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>Firstname</b>
                    </TableCell>
                    <TableCell align="left">{user?.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" scope="row">
                      <BadgeIcon />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>Lastname</b>
                    </TableCell>
                    <TableCell align="left">{user?.lastName}</TableCell>
                  </TableRow>
                </>
              )}

              {user?.Role.name === "company representative" && (
                <>
                  <TableRow>
                    <TableCell align="center" scope="row">
                      <BadgeIcon />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <b>Full Name</b>
                    </TableCell>
                    <TableCell align="left">
                      {user?.firstName + " " + user?.lastName}
                    </TableCell>
                  </TableRow>
                </>
              )}

              <TableRow>
                <TableCell align="center" scope="row">
                  <LocalPhoneIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Tel</b>
                </TableCell>
                <TableCell align="left">{user?.tel}</TableCell>
              </TableRow>
              {user?.Role.name !== "company representative" && (
                <TableRow>
                  <TableCell align="center" scope="row">
                    <AdminPanelSettingsIcon />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>Account</b>
                  </TableCell>
                  <TableCell align="left">
                    {user?.Role.name + " account"}
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell align="center" scope="row">
                  <UpdateIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Last update</b>
                </TableCell>
                <TableCell>
                  {moment.utc(user?.updatedAt).format("DD-MM-YYYY HH:mm")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  const renderCompany = () => {
    return (
      <>
        <br />
        <Typography color={"warning.main"} variant="h5" align="left">
          Company Details:
          <br />
          <br />
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TableCell align="center" scope="row">
                  <ApartmentIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Company</b>
                </TableCell>
                <TableCell align="left">{user?.Company?.name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell scope="row" align="center">
                  <MailIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Email</b>
                </TableCell>
                <TableCell align="left">{user?.Company?.email}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" scope="row">
                  <LocationOnIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Address</b>
                </TableCell>
                <TableCell align="left">{user?.Company?.address}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" scope="row">
                  <LocalPhoneIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Phone</b>
                </TableCell>
                <TableCell align="left">{user?.Company?.phone}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" scope="row">
                  <ClassIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>Sector</b>
                </TableCell>
                <TableCell align="left">{user?.Company?.sector}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <Container>
      <hr />
      { (role === "administrator" || role === "technician") &&( 
        <>
        {renderProfile()}
        </>
            
            
          
        )
      }
      {role === "company representative" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {renderProfile()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderCompany()}
          </Grid>
        </Grid>
      )}
      <br />
      <Typography color="error.main">
        You're datAHunt user since{" "}
        {moment.utc(user?.createdAt).format("DD-MM-YYYY HH:mm")}
      </Typography>
    </Container>
  );
}
