import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Container, Grid, Link, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCreateRequestMutation } from "../../redux/api/requestsApi";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import { Field } from "./requests.types";
import { getRoleFromLocalStorage } from "../../core/services/localStorage.service";
import Loader from "../Loader/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateRequest() {
  const [selectedOption, setSelectedOption] = useState("table");
  const [needTech, setNeedTech] = useState(false);
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [tableSelector, setTableSelector] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [reqTitle, setReqTitle] = useState("");
  const [unit, setUnit] = useState("");
  const [areaSelector, setAreaSelector] = useState("");
  const [fields, setFields] = useState<Field[]>([{ name: "", selector: "" }]);
  let navigate = useNavigate();
  const [CreateRequest , {isLoading}] = useCreateRequestMutation();
  const { data: category } = useGetCategoriesQuery();
  const [msg, setMsg] = useState({ status: undefined, data: null });
  const sortedCategories = category
    ? [...category].sort((a, b) => a.name.localeCompare(b.name.toString()))
    : [];

  const role = getRoleFromLocalStorage()

  const { handleSubmit } = useForm();
  const [categoryName, setCategoryName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event: any) => {
    setNeedTech(JSON.parse(event.target.value));
  };

  const handleAddField = () => {
    setFields([...fields, { name: "", selector: "" }]);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: keyof Field
  ) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = event.target.value;
    setFields(updatedFields);
  };

  const onSubmit = async () => {
    const selectedCategoryIds = category
      ?.filter((c) => categoryName.includes(c.name.toString()))
      .map((c) => c.id);
    let msgObj: any = {};
    if(needTech){
        let data = {
          website: { url},
          title : reqTitle,
          description : description,
          needTech: needTech,
        };
        let response: any = await CreateRequest(data);
        if (response?.data) {
          msgObj.status = "success";
          msgObj.data = "request created successfuly!";
          setMsg(msgObj);
          navigate("/requestDetails/"+response?.data?.id)
        } else {
          msgObj.status = "error";
          if (response.error.data.message)
            msgObj.data = response.error.data.message;
          else msgObj.data = response.error.data;
          setMsg(msgObj);
        }
    } else if (selectedOption === "table") {
      let data = {
        website: {
          url,
          table: { selector: tableSelector, header: header, body, title, unit },
        },
        title : reqTitle,
        needTech: needTech,
        categoryIDs: selectedCategoryIds,
      };
      let response: any = await CreateRequest(data);
      if (response?.data) {
        msgObj.status = "success";
        msgObj.data = "request created successfuly!";
        setMsg(msgObj);
        navigate("/requestDetails/"+response?.data?.id)
      } else {
        msgObj.status = "error";
        msgObj.data = response.error.data;
        setMsg(msgObj);
      }
    } else {
      let data = {
        website: { url, selector: areaSelector, fields },
        title : reqTitle,
        categoryIDs: selectedCategoryIds,
        needTech: needTech
      };
      let response: any = await CreateRequest(data);
      if (response?.data) {
        msgObj.status = "success";
        msgObj.data = "request created successfuly!";
        setMsg(msgObj);
        navigate("/requestDetails/"+response?.data?.id)
      } else {
        msgObj.status = "error";
        if (response.error.data.message)
          msgObj.data = response.error.data.message;
        else msgObj.data = response.error.data;
        setMsg(msgObj);
      }
    }
  };
  const adminForm = ()=>{
    return(
      <form id="requestForm" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
                  variant="standard"
                  label="Request Title"
                  value={reqTitle}
                  onChange={(event) => setReqTitle(event.target.value)}
                  required
                  
                />
      <TextField
              variant="standard"
              label="URL"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
              required
              inputProps={{
                pattern:
                  "^(https?://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*?)?$",
              }}
            />
            <br/>
        <RadioGroup
          sx={{ display: "flex", alignItems: "center" }}
          row
          value={selectedOption}
          onChange={handleOptionChange}
          
        >
          <InputLabel sx={{ marginRight: "16px", color: "black"}}>Type: </InputLabel>
          <FormControlLabel
            value="table"
            control={<Radio />}
            label="Table"
            disabled={needTech}
          />
          <FormControlLabel
            value="fields"
            control={<Radio />}
            label="Fields"
            disabled={needTech}
          />
        </RadioGroup>

        {selectedOption === "table" ? (
          <>
            <TextField
              variant="standard"
              label="Table Selector"
              value={tableSelector}
              onChange={(event) => setTableSelector(event.target.value)}
              required
              disabled={needTech}
            />
            <TextField
              variant="standard"
              label="Header"
              value={header}
              onChange={(event) => setHeader(event.target.value)}
              required
              disabled={needTech}
            />
            <TextField
              variant="standard"
              label="Body"
              value={body}
              onChange={(event) => setBody(event.target.value)}
              required
              disabled={needTech}
            />
            <TextField
              variant="standard"
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              disabled={needTech}
            />
            <TextField
              variant="standard"
              label="Unit"
              value={unit}
              onChange={(event) => setUnit(event.target.value)}
              disabled={needTech}
            />
          </>
        ) : (
          <>
            <TextField
              variant="standard"
              label="Area Selector"
              value={areaSelector}
              onChange={(event) => setAreaSelector(event.target.value)}
              required
              disabled={needTech}
            />
            <br />
            <Typography
              variant="h6"
              style={{ textDecoration: "underline", textAlign: "left" }}
            >
              Fields:
            </Typography>
            {fields.map((field, index) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      label="Name"
                      fullWidth
                      value={field.name}
                      disabled={needTech}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => handleFieldChange(event, index, "name")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      label="Selector"
                      fullWidth
                      value={field.selector}
                      disabled={needTech}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => handleFieldChange(event, index, "selector")}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
            <br />
            <Grid style={{ textAlign: "left" }}>
              <Link
                component="button"
                sx={{ mt: 3, ml: 1 }}
                onClick={handleAddField}
                disabled={needTech}
              >
                Add Field
              </Link>
              {fields.length > 1 && (
                <Link
                  component="button"
                  sx={{ mt: 3, ml: 3 }}
                  onClick={() => setFields(fields.slice(0, -1))}
                  disabled={needTech}
                >
                  Remove Field
                </Link>
              )}
            </Grid>
          </>
        )}
        <br />
        <Grid style={{ textAlign: "left" }}>
          <InputLabel>Categories</InputLabel>
          <Select
            label="Categories"
            id="categories"
            disabled={needTech}
            multiple
            value={categoryName}
            fullWidth
            onChange={handleChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="Category" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {sortedCategories.map((cat) => (
              <MenuItem key={cat.id.toString()} value={cat.name.toString()}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid style={{ textAlign: "right" }}>
            {isLoading? (
              <>
              <Typography color={"primary"}>This Operation will take some time please wait</Typography>
              <Loader/>
              </>)  : (
                <>
                <Button
                onClick={() => navigate("/requestslist")}
                sx={{ mt: 3, ml: 1 }}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                Submit
              </Button></>
              )  }
            </Grid>
      </Box>
    </form>
    )
  }
  const companyRepForm = ()=>{
    return(
    <form id="requestForm" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
                  variant="standard"
                  label="Request Title"
                  value={reqTitle}
                  onChange={(event) => setReqTitle(event.target.value)}
                  required
                  
                />
          <TextField
                  variant="standard"
                  label="URL"
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                  required
                  inputProps={{
                    pattern:
                      "^(https?://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*?)?$",
                  }}
                />
                <br/>

                <RadioGroup
              sx={{ display: "flex", alignItems: "center" }}
              row
              value={needTech}
              onChange={handleOptionChange1}
            >
              <InputLabel sx={{ marginRight: "16px", color:"black" }}>Do you need a technician? </InputLabel>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {needTech === true ? (
              <TextField
                variant="standard"
                label="Description"
                value={description}
                multiline
                onChange={(event) => setDescription(event.target.value)}
                required
              />
            ) : null}
            <RadioGroup
              sx={{ display: "flex", alignItems: "center" }}
              row
              value={selectedOption}
              onChange={handleOptionChange}
              
            >
              <InputLabel sx={{ marginRight: "16px", color: "black"}}>Type: </InputLabel>
              <FormControlLabel
                value="table"
                control={<Radio />}
                label="Table"
                disabled={needTech}
              />
              <FormControlLabel
                value="fields"
                control={<Radio />}
                label="Fields"
                disabled={needTech}
              />
            </RadioGroup>

            {selectedOption === "table" ? (
              <>
                <TextField
                  variant="standard"
                  label="Table Selector"
                  value={tableSelector}
                  onChange={(event) => setTableSelector(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Header"
                  value={header}
                  onChange={(event) => setHeader(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Body"
                  value={body}
                  onChange={(event) => setBody(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Title"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Unit"
                  value={unit}
                  onChange={(event) => setUnit(event.target.value)}
                  disabled={needTech}
                />
              </>
            ) : (
              <>
                <TextField
                  variant="standard"
                  label="Area Selector"
                  value={areaSelector}
                  onChange={(event) => setAreaSelector(event.target.value)}
                  required
                  disabled={needTech}
                />
                <br />
                <Typography
                  variant="h6"
                  style={{ textDecoration: "underline", textAlign: "left" }}
                >
                  Fields:
                </Typography>
                {fields.map((field, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          label="Name"
                          fullWidth
                          value={field.name}
                          disabled={needTech}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleFieldChange(event, index, "name")}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="standard"
                          label="Selector"
                          fullWidth
                          value={field.selector}
                          disabled={needTech}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleFieldChange(event, index, "selector")}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <br />
                <Grid style={{ textAlign: "left" }}>
                  <Link
                    component="button"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={handleAddField}
                    disabled={needTech}
                  >
                    Add Field
                  </Link>
                  {fields.length > 1 && (
                    <Link
                      component="button"
                      sx={{ mt: 3, ml: 3 }}
                      onClick={() => setFields(fields.slice(0, -1))}
                      disabled={needTech}
                    >
                      Remove Field
                    </Link>
                  )}
                </Grid>
              </>
            )}
            <br />
            <Grid style={{ textAlign: "left" }}>
              <InputLabel>Categories</InputLabel>
              <Select
                label="Categories"
                id="categories"
                disabled={needTech}
                multiple
                value={categoryName}
                fullWidth
                onChange={handleChange}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Category" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {sortedCategories.map((cat) => (
                  <MenuItem key={cat.id.toString()} value={cat.name.toString()}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
            {isLoading? (
              <>
              <Typography color={"primary"}>This Operation will take some time please wait</Typography>
              <Loader/>
              </>)  : (
                <>
                <Button
                onClick={() => navigate("/requestslist")}
                sx={{ mt: 3, ml: 1 }}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                Submit
              </Button></>
              )  }
            </Grid>
          </Box>
        </form>);
  }
  return (
    <Container>
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {msg.status && (
          <Box>
            <Alert severity={msg.status}>{msg.data}</Alert>
            <br />
          </Box>
        )}
        <Typography component="h1" variant="h2" align="center">
          Create New Request
        </Typography>
        <br />
        { role === "company representative" ? companyRepForm() : adminForm()}
      </Paper>
    </Container>
  );
}
