import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Alert,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useUpdateRequestMutation,
  useGetRequestQuery,
  useSetRequestStateMutation,
} from "../../redux/api/requestsApi";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import { Category } from "../../core/models/types";
import { Field } from "./requests.types";
import { useRole } from "../../hooks/authHooks";
import Loader from "../Loader/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UpdateRequest() {
  let role = useRole();
  let previous = "";
  if (role === "technician") {
    previous = "/assignedToMe";
  } else {
    previous = "/requestslist";
  }
  const location = window.location.href;
  const locationParts = location.split("/");
  const id = locationParts[locationParts.length - 1]; // get second-to-last element

  const { data: existingReq } = useGetRequestQuery(id);

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("table");
  const [url, setUrl] = useState(existingReq?.website?.url ?? "");
  const [reqTitle, setReqTitle] = useState(existingReq?.title);
  const [tableSelector, setTableSelector] = useState(
    existingReq?.website?.table?.selector
  );
  const [header, setHeader] = useState(existingReq?.website?.table?.header);
  const [body, setBody] = useState(existingReq?.website?.table?.body);
  const [title, setTitle] = useState(existingReq?.website?.table?.title);
  const [unit, setUnit] = useState(existingReq?.website?.table?.unit ?? "");

  const [areaSelector, setAreaSelector] = useState(
    existingReq?.website?.selector
  );
  const [fields, setFields] = useState<Field[]>(existingReq?.website?.fields);
  const [categories, setCategories] = useState<Category[] | undefined>(
    existingReq?.categories
  );
  const [categoryName, setCategoryName] = useState<String[]>(
    existingReq?.categories?.map((cat) => cat.name) || []
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [reason, setReason] = useState(existingReq?.techMsg ?? "");

  useEffect(() => {
    if (existingReq) {
      setReqTitle(existingReq?.title);
      setUrl(existingReq?.website?.url);
      setCategoryName(existingReq?.categories?.map((cat) => cat.name));
      setCategories(existingReq?.categories);
      if (existingReq?.website?.table) {
        setTableSelector(existingReq?.website?.table?.selector);
        setHeader(existingReq?.website?.table?.header);
        setBody(existingReq?.website?.table?.body);
        setTitle(existingReq?.website?.table?.title);
        setUnit(existingReq?.website?.table?.unit);
        setSelectedOption("table");
      } else {
        setAreaSelector(existingReq?.website?.selector);
        setFields(existingReq?.website?.fields);
        setSelectedOption("fields");
      }
    }
  }, [existingReq]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let navigate = useNavigate();
  const [UpdateRequest, { isLoading }] = useUpdateRequestMutation();
  const [setRequestState] = useSetRequestStateMutation();
  const { data: category } = useGetCategoriesQuery();
  const [msg, setMsg] = useState({ status: undefined, data: null });
  const sortedCategories = category
    ? [...category].sort((a, b) => a.name.localeCompare(b.name.toString()))
    : [];

  const { handleSubmit, reset } = useForm();
  const handleDecline = async () => {
    let state = "failed";
    let techMsg = reason;
    let response: any = await setRequestState({ state, techMsg, id });
    let msgObj: any = {};
    if (response?.data) {
      msgObj.status = "success";
      msgObj.data = "request state updated successfuly!";
      setMsg(msgObj);
      setOpen(false);
      navigate(previous);
      setSuccess(true);
    } else {
      msgObj.status = "error";
      msgObj.error.data = response.error.data;
      setMsg(msgObj);
      setOpen(false);
    }
  };
  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value || []
    );
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleAddField = () => {
    {
      fields
        ? setFields([...fields, { name: "", selector: "" }])
        : setFields([{ name: "", selector: "" }]);
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: keyof Field
  ) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = event.target.value;
    setFields(updatedFields);
  };

  const onSubmit = async () => {
    // Get the IDs of the selected categories
    const selectedCategoryIds = category
      ?.filter((c) => categoryName.includes(c.name.toString()))
      .map((c) => c.id);
    let msgObj: any = {};
    let data;
    if (selectedOption === "table") {
      data = {
        id: id,
        website: {
          url,
          table: { selector: tableSelector, header: header, body, title, unit },
        },
        title: reqTitle,
        categoryIDs: selectedCategoryIds,
        needTech: existingReq?.needTech,
      };
      let response: any = await UpdateRequest(data);
      if (response?.data) {
        msgObj.status = "success";
        msgObj.data = "request  updated successfuly!";
        setMsg(msgObj);
        setOpenDialog(true);
        setDialogMessage("Password reset successfully!");

        reset();
        //navigate(previous);
      } else {
        msgObj.status = "error";
        msgObj.data = response.error.data;
        setMsg(msgObj);
      }
    } else {
      data = {
        id: id,
        website: { url, selector: areaSelector, fields },
        title: reqTitle,
        categoryIDs: selectedCategoryIds,
      };
      let response: any = await UpdateRequest(data);
      if (response?.data) {
        msgObj.status = "success";
        setSuccess(true);
        msgObj.data = "request  updated successfuly!";
        setMsg(msgObj);

        setOpenDialog(true);
        setDialogMessage("Password reset successfully!");

        reset();
        //navigate(previous);
      } else {
        msgObj.status = "error";
        msgObj.data = response.error.data;
        setMsg(msgObj);
      }
    }
  };
  return (
    <Container>
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {msg.status && (
          <Box>
            <Alert severity={msg.status}>{msg.data}</Alert>
            <br />
          </Box>
        )}
        <Grid container alignItems="center" justifyContent="center">
          {role === "administrator" && (
            <>
              <Typography component="h1" variant="h2" align="center">
                Update Public Request
                <br />
                <br />
              </Typography>
            </>
          )}
          {role === "technician" && (
            <>
              <Grid item xs={10}>
                <Typography component="h1" variant="h2" align="center">
                  Handle Request
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickOpen}
                >
                  Decline
                </Button>
              </Grid>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Decline Request</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    You are about to decline this request. Please specify the
                    reason why this request can not be handled.
                  </DialogContentText>
                  <TextField
                    id="reason"
                    label="Reason"
                    multiline
                    maxRows={5}
                    fullWidth
                    variant="filled"
                    onChange={(event) => setReason(event.target.value)}
                    required
                    value={"" + reason}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleDecline}>Decline</Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Grid>
        {existingReq && (
          <>
            <form id="requestForm" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  variant="standard"
                  label="URL"
                  defaultValue={url}
                  onChange={(event) => setUrl(event.target.value)}
                  required
                  value={"" + url}
                />
                <TextField
                  variant="standard"
                  label="Request Title"
                  onChange={(event) => setReqTitle(event.target.value)}
                  required
                  value={"" + reqTitle}
                />

                <RadioGroup
                  row
                  aria-label="request-type"
                  name="request-type"
                  value={selectedOption.toString()}
                  onChange={handleOptionChange}
                >
                  <FormControlLabel
                    value="table"
                    control={<Radio />}
                    label="Table"
                  />
                  <FormControlLabel
                    value="fields"
                    control={<Radio />}
                    label="Fields"
                  />
                </RadioGroup>

                {selectedOption === "table" ? (
                  <>
                    <TextField
                      variant="standard"
                      label="Title" //defaultValue={title}
                      onChange={(event) => setTitle(event.target.value)}
                      value={"" + title}
                    />
                    <TextField
                      variant="standard"
                      label="Unit"
                      defaultValue={unit}
                      onChange={(event) => setUnit(event.target.value)}
                      value={"" + unit}
                    />
                    <TextField
                      variant="standard"
                      label="Table Selector" //defaultValue={tableSelector}
                      onChange={(event) => setTableSelector(event.target.value)}
                      required
                      value={"" + tableSelector}
                    />

                    <TextField
                      variant="standard"
                      label="Header" //defaultValue={header}
                      onChange={(event) => setHeader(event.target.value)}
                      value={"" + header}
                    />
                    <TextField
                      variant="standard"
                      label="Body" //defaultValue={body}
                      onChange={(event) => setBody(event.target.value)}
                      required
                      value={"" + body}
                    />
                  </>
                ) : (
                  <>
                    {/*  <TextField variant="standard" label="URL" defaultValue={url} onChange={(event) => setUrl(event.target.value)} required />
                     */}{" "}
                    <TextField
                      variant="standard"
                      label="Area Selector"
                      defaultValue={areaSelector}
                      onChange={(event) => setAreaSelector(event.target.value)}
                      required
                      value={"" + areaSelector}
                    />
                    <br />
                    <Typography
                      variant="h6"
                      style={{ textDecoration: "underline", textAlign: "left" }}
                    >
                      Fields:
                    </Typography>
                    {fields &&
                      fields.map((field, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="standard"
                                label="Name"
                                fullWidth
                                defaultValue={field.name}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => handleFieldChange(event, index, "name")}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                variant="standard"
                                label="Selector"
                                fullWidth
                                defaultValue={field.selector}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleFieldChange(event, index, "selector")
                                }
                                required
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    <br />
                    <Grid style={{ textAlign: "left" }}>
                      <Link
                        component="button"
                        sx={{ mt: 3, ml: 1 }}
                        onClick={handleAddField}
                      >
                        Add Field
                      </Link>
                      {fields?.length > 1 && (
                        <Link
                          component="button"
                          sx={{ mt: 3, ml: 3 }}
                          onClick={() => setFields(fields.slice(0, -1))}
                        >
                          Remove Field
                        </Link>
                      )}
                    </Grid>
                  </>
                )}
                <br />
                <Grid style={{ textAlign: "left" }}>
                  <InputLabel>Categories</InputLabel>
                  <Select
                    label="Categories"
                    id="categories"
                    multiple
                    value={categoryName}
                    fullWidth
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Category"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.toString()} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {sortedCategories.map((cat) => (
                      <MenuItem
                        key={cat.id.toString()}
                        value={cat.name.toString()}
                      >
                        {cat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid style={{ textAlign: "right" }}>
                  {isLoading ? (
                    <>
                      <Typography color="primary">
                        This Operation will take some time, please wait
                      </Typography>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {role === "technician" ? (
                        <>
                          <Button
                            onClick={() => navigate("/assignedToMe")}
                            sx={{ mt: 3, ml: 1 }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => navigate("/requestslist")}
                            sx={{ mt: 3, ml: 1 }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Grid>
              </Box>
            </form>
          </>
        )}
      </Paper>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate("/requestDetails/" + id, { replace: true })}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
