import { Box, Container, Paper, Typography } from "@mui/material";
import RoleComponent from "../components/roleComponent";

export default function RolePage(){
    return(
        <Box>
            <Container>
                <br/>
            
            <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
                Roles
            </Typography>
            <hr/>
                <RoleComponent/>
            </Paper>
            </Container>
            
        </Box>
        
    )
}