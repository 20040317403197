import React from 'react';

import { Typography  } from "@mui/material";
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';


const LinkedInProfileComponent: React.FC<any> = ({ profile }) => {
  const { name, country, headline } = profile.objprofile;
//console.log("1pro:",profile)
  return (
    <div>
        <Typography component="h2" variant="h3" align="center" color={"primary"}>
        <MuiLink component={Link} to={profile.link} color="primary">
    {name}
  </MuiLink>
        </Typography>
        <Typography component="h2" variant="h5" align="center" color={"primary"}>
           {headline}
        </Typography>
        <Typography color={"primary"} align="center">
            {country}
        </Typography>
        
    </div>
  );
};

export default LinkedInProfileComponent;
