import React from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { Typography } from '@mui/material';


interface EducationProps {
  education: {
    degree: string;
    school: string;
    dateRange: string;
  }[];
}

const LinkedInEducationComponent: React.FC<EducationProps> = ({ education }) => {
  return (
    <div style={{ textAlign: 'justify' }}>
        <br/>
      <Typography variant="h5" color="error" style={{ fontWeight: 'bold' }}> <SchoolIcon/> Education</Typography>
      {education.map((edu, index) => (
        <div key={index}>
          <Typography color="primary" style={{ fontWeight: 'bold' }}>{edu.degree}</Typography>
          <Typography  color="black">{edu.school}</Typography>
          <Typography color="grey">{edu.dateRange}</Typography>
        </div>
      ))}
    </div>
  );
};

export default LinkedInEducationComponent;
