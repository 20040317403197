import { Paper, Container, Skeleton, Typography, Grid } from "@mui/material";
import { Line } from "react-chartjs-2";
import theme from "../shared/theme/theme";
import { useGetCleanDataBySubjectQuery } from "../redux/api/dataApi";
import { CONFIG } from "../core/constant/config";
import { custom } from "../shared/theme/theme";

export default function FinanceChart() {
  const encodedString_Interest = encodeURIComponent(
    CONFIG.DASHBOARD.InterestRate
  );
  const encodedString_Exchange = encodeURIComponent(
    CONFIG.DASHBOARD.AverageExchangeRate
  );
  const { data, isLoading, isFetching } = useGetCleanDataBySubjectQuery(
    encodedString_Interest
  );
  const {
    data: exchange,
    isLoading: loadingExchange,
    isFetching: fetchingExchange,
  } = useGetCleanDataBySubjectQuery(encodedString_Exchange);

  if (isLoading || isFetching || loadingExchange || fetchingExchange) {
    return (
      <div>
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
      </div>
    );
  }

  let object: any = data?.data[0];
  let exchangeObj: any = exchange?.data[0];

  const firstKey = Object.keys(object)[0];
  const firstKeyExchange = Object.keys(exchangeObj)[0];

  const secondKey = Object.keys(object)[1];
  const secondKeyExchange = Object.keys(exchangeObj)[1];

  const thirdKey = Object.keys(object)[2];
  //const thirdKeyExchange = Object.keys(exchangeObj)[2];

  const fourthKeyExchange = Object.keys(exchangeObj)[3];
  const fifthKeyExchange = Object.keys(exchangeObj)[4];

  const labels = data?.data?.map((obj: any) => obj[firstKey]);
  const labelsExchange = exchange?.data?.map(
    (obj: any) => obj[firstKeyExchange]
  );

  const lineOne_values = data?.data?.map((obj: any) => obj[secondKey]);
  const lineTwo_values = data?.data?.map((obj: any) => obj[thirdKey]);

  const lineOne_Exchange = exchange?.data?.map(
    (obj: any) => obj[secondKeyExchange]
  );
  // const lineTwo_Exchange = exchange?.data?.map( (obj: any) => obj[thirdKeyExchange]);
  const line3_Exchange = exchange?.data?.map(
    (obj: any) => obj[fourthKeyExchange]
  );
  const line4_Exchange = exchange?.data?.map(
    (obj: any) => obj[fifthKeyExchange]
  );

  const data1 = {
    labels: labels,
    datasets: [
      {
        label: secondKey,
        data: lineOne_values,
        borderColor: theme.palette.info.main,

        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: thirdKey,
        data: lineTwo_values,
        borderColor: theme.palette.secondary.main,

        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      },
    ],
  };

  const exchange1 = {
    labels: labelsExchange,
    datasets: [
      {
        label: fourthKeyExchange,
        data: line3_Exchange,
        borderColor: theme.palette.secondary.main,

        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      {
        label: secondKeyExchange,
        data: lineOne_Exchange,
        borderColor: theme.palette.info.main,

        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      },

      {
        label: fifthKeyExchange,
        data: line4_Exchange,
        borderColor: custom.indigo,

        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      },
      /* {
        label: thirdKeyExchange,
        data: lineTwo_Exchange,
        borderColor: theme.palette.info.main,
  
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 10,
      }, */
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        ticks: {
          suggestedMin: 0,
          suggestedMax: 50,
        },
      },
    },
  } as any;

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              align="center"
              color="primary"
              fontWeight={500}
            >
              {data?.subject}
            </Typography>
            <Line data={data1} options={options} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              align="center"
              color="primary"
              fontWeight={500}
            >
              {exchange?.subject}
            </Typography>
            <Line data={exchange1} options={options} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
