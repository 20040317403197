import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {useForm} from "react-hook-form";
import { Alert } from "@mui/material";
import { useResetPasswordMutation } from "../redux/api/authApi";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useRef, useState } from "react";


export default function ResetPassword() {
  const { register, formState: { errors }, handleSubmit, watch } = useForm({});
  const [resetPw] = useResetPasswordMutation()
  const [err, setErr] = useState();
  const [success, setSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const password = useRef({});
  const navigate = useNavigate();
  password.current = watch("password", "");

  // Add state variables to track whether password fields are visible
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  

  const onSubmit =  async (data: any) => {
    const url = window.location.href
    const urlParts = url.split('/');
    const id = urlParts[urlParts.length - 2]; // get second-to-last element
    const token = urlParts[urlParts.length - 1]; // get last element

    const response : any =  await resetPw({data,id, token})
    if(response.data){
      setSuccess(true)
      setOpenDialog(true);
      setDialogMessage('Password reset successfully!');
      // navigate to login page
      //navigate('/login', { replace: true });
    } else{
      setErr(response.error.data);
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 3,
            }}
          >
            { err && 
            <Box>
              <Alert severity="error">{err}</Alert>
              <br/>
            </Box>
          }
          { success && 
            <Box>
              <Alert severity="success">Password reset successfuly!</Alert>
              <br/>
            </Box>
          }
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Set Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="New Password"
                type={showPassword1 ? "text" : "password"}
                id="password"
                {...register("password", {required: "You must specify a password"})}
                error={Boolean(errors.password)}
                helperText={errors.password && "Password is required"}
                /* InputProps={{ // Add icon button to toggle visibility
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword1(!showPassword1)}>
                      {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )
                }} */
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type={showPassword2 ? "text" : "password"}
                {...register("password2", {
                  required: true,
                  validate: value =>
                    value === password.current || "The passwords do not match"
                })}
                error={Boolean(errors.password2)}
                helperText={errors.password2 && "The passwords do not match"}
                /* InputProps={{ // Add icon button to toggle visibility
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword2(!showPassword2)}>
                      {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )
                }} */
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
        </Paper>
        <Dialog
        open={success}
        onClose={() => navigate("/login", { replace: true })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Password has been reset successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate("/login", { replace: true })}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </Container>
    </div>
  );
}