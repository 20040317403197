import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  Alert,
  FormControl,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useCreateUserMutation } from "../redux/api/usersApi";
import { useNavigate } from "react-router-dom";
export default function AddUserForm() {
  const [msg, setMsg] = useState({ status: undefined, data: null });
 let navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [createUser] = useCreateUserMutation();

  const onSubmit = async (data: any) => {
    const response: any = await createUser(data);
    let msgObj: any = {};
    if (response.error) {
      msgObj.status = "error";
      msgObj.data = response.error.data;
      setMsg(msgObj);
    } else {
      msgObj.status = "success";
      msgObj.data = "user created successfuly!";
      setMsg(msgObj);
      reset();
    }
  };
  return (
    <Container>
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {msg.status && (
          <Box>
            <Alert severity={msg.status}>{msg.data}</Alert>
            <br />
          </Box>
        )}
        <Typography variant="h2" gutterBottom>
          Create User Account
        </Typography>
        <Box
          component="form"
          id="formuser"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              label="First name"
              fullWidth
              variant="standard"
              {...register("firstName", { required: true })}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName && "First name is required"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              label="Last name"
              fullWidth
              variant="standard"
              {...register("lastName", { required: true, maxLength: 20 })}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && "Last name is required"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="Email"
              fullWidth
              variant="standard"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              error={Boolean(errors.email)}
              helperText={errors.email && "Please enter a valid email address"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="tel"
              label="Phone Number"
              fullWidth
              variant="standard"
              {...register("tel", {
                required: "This input is required.",
                pattern: /^[0-9]{8}$/,
              })}
              error={Boolean(errors.tel)}
              helperText={
                errors.tel && "Phone number must contain exactly 8 numbers"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              variant="standard"
              {...register("password", { required: true })}
              error={Boolean(errors.password)}
              helperText={errors.password && "Password is required"}
              InputProps={{
                // Add icon button to toggle visibility
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} my={1}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <NativeSelect
                required
                defaultValue={"technician"}
                {...register("role", {
                  required: "This input is required.",
                })}
                //onChange={handleChange}
                size="small"
              >
                <option value={"technician"}>Technician</option>
                <option value={"administrator"}>Administrator</option>
                <option value={"company representative"}>
                  Company Representaive
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid>
              <Button onClick={()=>navigate("/userslist")} sx={{ mt: 3, ml: 1 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                Create User
              </Button>{" "}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
