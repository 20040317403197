import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import AnonymousProRegular from '../fonts/anonymous-pro/anonymous-pro-v21-latin-regular.svg';
import athiti from '../fonts/athiti/athiti-v12-latin-regular.svg'



export const custom= {
  lavender:"#E6E6FA",
  paleBlue: "#F0F8FF ", 
  indigo: " #690da5"
}
const theme = createTheme({
  palette: {
    primary: {
      main: '#03345c',
    },
    secondary: {
      main: '#FF377D',
    },
    success: {
      main: '#2dce89',
    },
    info: {
      main: '#11cdef',
    },
    warning: {
      main: '#ffd737',
    },
    error: {
      main: '#f5365c',
    },
    /* background: {
      default: '#0a0117',
    }, */
    action: {
      active: "#fff",
    },
    
  },
  
  components: {
   /*  MuiIconButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '1rem',
          "&:hover": {
            backgroundColor: "success.dark",
          },
        },
      },
    },*/
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#F0F8FF', // set the background color of chips
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          //color: 'white',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'grey', 
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: 'black', 
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0059A1',
         //backgroundColor: '#03345c'
        },
        
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
          '& .MuiBreadcrumbs-separator': {
            color: '#ddd',
          },
          '& .MuiBreadcrumbs-ol': {
            '& .MuiBreadcrumbs-li': {
              '&:not(:last-of-type)': {
                '& .MuiBreadcrumbs-separator': {
                  color: '#ddd',
                },
              },
            },
            '& .MuiBreadcrumbs-li a': {
              color: 'white',
            },
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [
            {
              fontFamily: 'Anonymous Pro',
              src: `url(${AnonymousProRegular}) format('truetype')`,
              fontWeight: 'normal',
              fontStyle: 'normal',
            },
            {
              fontFamily: 'Athiti',
              src: `url(${athiti}) format('truetype')`,
              fontWeight: 'normal',
              fontStyle: 'normal',
            },
          ],
        },
      },
    },
  },
  typography: {
    fontFamily: 'Anonymous Pro, Roboto, sans-serif,',
    fontSize: 16,
    h1: {
      fontWeight: 700,
      color: '#000', 
      fontFamily: 'Anonymous Pro, Roboto, sans-serif,',
    },
    h2: {
      fontWeight: 700,
      color: '#000',
      fontFamily: 'Anonymous Pro, Roboto, sans-serif,',
    },
    h3: {
      fontWeight: 700,
      color: '#000', 
      fontFamily: ' Anonymous Pro, Roboto, sans-serif,',
    },
    body1: {
      fontSize: '1rem',
      color: '#fff', 
    },
  },
  
});

const responsiveTheme = responsiveFontSizes(theme);
export default responsiveTheme;
