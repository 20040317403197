//import csv from "csvtojson";
import  { Fragment, useRef, useState } from "react";
import {
  useCreateDataMutation,
  useDeleteDataMutation,
  useGetCleanDataByReqQuery,
} from "../redux/api/dataApi";
import Papa from "papaparse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Button,
  IconButton,
  Container,
  Skeleton,
  Typography,
  Alert,
} from "@mui/material";
import dataTableTheme from "../shared/theme/dataTableTheme";
import { useGetRequestQuery } from "../redux/api/requestsApi";
import DataTable from "react-data-table-component";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "./Loader/Loader";

export default function CleanData({ reqId }: { reqId: String }) {
  const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input element

  const sortIcon = <ArrowDownward />;
  //const { data: request } = useGetRequestQuery(reqId);
  const { data: cleanData, isLoading } = useGetCleanDataByReqQuery(reqId);
  const [createData] = useCreateDataMutation();
  const [deleteData, { isLoading: isLoadingDelete }] = useDeleteDataMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [msg, setMsg] = useState({ status: undefined, data: null });
  const [dataToDelete, setDataToDelete] = useState("");

  //console.log("request:", request);

  if (isLoading) {
    return (
      <div>
        <div>
          <br />
          <Skeleton variant="rectangular" height={15} />
          <br />
          <Skeleton variant="rectangular" height={15} />
          <br />
          <Skeleton variant="rectangular" height={15} />
        </div>
      </div>
    );
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    let msgObj: any = {};
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const csvContent = reader.result as string;
        const parsedData = parseCSV(csvContent);
        const titleWithoutExtension = extractTitleWithoutExtension(file.name);
        const cleanData = {
          data: parsedData.data,
          requestId: reqId,
          subject: titleWithoutExtension,
        };
        // console.log("cleanData:!!!", cleanData);
        const response: any = await createData(cleanData);
        //console.log("response!!!!!", response);
        if (response.error) {
          msgObj.status = "error";
          msgObj.data = response.error.data;
          setMsg(msgObj);
        } else {
          msgObj.status = "success";
          msgObj.data = "Clean Data Inserted Successfuly!";
          setMsg(msgObj);
        }
      };
      reader.readAsText(file);
    } 
    /* else {
      console.log("file empty!");
    } */
    // Reset the file input value to allow selecting the same file multiple times
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const extractTitleWithoutExtension = (filename: string) => {
    const lastIndex = filename.lastIndexOf(".");
    if (lastIndex === -1) {
      return filename; // No extension found
    }
    return filename.substring(0, lastIndex);
  };

  const parseCSV = (
    csvContent: string
  ): { data: any[]; tableColumns: string[] } => {
    const { data, meta } = Papa.parse(csvContent, {
      header: true, // Treat the first row as headers
      dynamicTyping: true, // Automatically convert values to appropriate types
    });

    // Extract the headers from the meta object
    const headers = meta.fields || [];

    setTableColumns(headers);
    setData(data);
    //console.log("headers", headers);
    //console.log("data", data);

    return {
      data,
      tableColumns: headers,
    };
  };

  const columns = (item: any) => {
    const cols = Object.keys(item?.data[0]);
    let rows: any = [];
    cols.map((col: any) => {
      rows.push({
        name: (
          <div>
            <h2>{col.toUpperCase()}</h2>
          </div>
        ),
        selector: (row: any) => row[col],
        sortable: true,
        style: {
          wordWrap: "break-word",
          overflowWrap: "break-word",
        },
      });
    });
    return rows;
  };
  const handleDelete = async (id: any) => {
    let msgObj: any = {};
    let response: any = await deleteData(id);
    if (response.error) {
      msgObj.status = "error";
      msgObj.data = response.error.data;
      setMsg(msgObj);
    } else {
      msgObj.status = "success";
      msgObj.data = "Data Deleted Successfuly!";
      setMsg(msgObj);
    }
  };
  const toggleDelete = (id: string) => {
    setDataToDelete(id);

    setIsDeleteDialogOpen(true);
  };
  const renderData = () => {
    const tableComponents = cleanData.map((item: any) => {
      return (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              color="error"
              aria-label="delete"
              component="span"
              onClick={() => toggleDelete(item.id)}
            >
              <DeleteForeverSharpIcon />
            </IconButton>
          </div>

          <DataTable
            columns={columns(item)}
            data={item?.data}
            pagination
            sortIcon={sortIcon}
            noTableHead={false}
            striped
            highlightOnHover
            customStyles={dataTableTheme}
            responsive={true}
            title={item.subject || ""}
          />
        </div>
      );
    });
    return <>{tableComponents}</>;
  };

  //console.log("cleanData", cleanData);

  return (
    <>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        {!isLoadingDelete ? (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this data?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setIsDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await handleDelete(dataToDelete);
                  setIsDeleteDialogOpen(false);
                }}
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Loading ... </p>
              <Loader />
            </div>
          </>
        )}
      </Dialog>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mx:2
        }}
      >
        <Typography align="left" variant="h5" sx={{ ml: 2 }}>
          Clean data
        </Typography>
        <br />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "flex-end",
           
          }}
        >
          {/* <input type="file" onChange={handleFileUpload} /> */}
          <div>
            <input
              accept=".csv"
              style={{ display: "none" }}
              id="file-input"
              type="file"
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
            <label htmlFor="file-input">
              <Button 
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Choose File
              </Button>
            </label>
          </div>
        </Box>
      </Box>
      <br />
      {msg.status && (
        <Box>
          <Alert severity={msg.status}>{msg.data}</Alert>
          <br />
        </Box>
      )}

      {cleanData && (
        <>
          <Container>{renderData()}</Container>
        </>
      )}
    </>
  );
}
