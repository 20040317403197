import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useGetProfileQuery,  useUpdateProfileMutation } from "../redux/api/usersApi";
import { getRoleFromLocalStorage } from "../core/services/localStorage.service";
import { useNavigate } from "react-router-dom";

export default function UpdateProfile() {
  const [msg, setMsg] = useState({ status: undefined, data: null });
  const [success, setSuccess] = useState(false);
  const url = window.location.href;
  const urlParts = url.split("/");
  const navigate = useNavigate();
  const id = urlParts[urlParts.length - 1]; // get second-to-last element
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const role = getRoleFromLocalStorage();
  const {
    data: user,
  } = useGetProfileQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [updateProfile] = useUpdateProfileMutation();
  const onSubmit = async (data: any) => {
    data.id = id;
    if(role === "company representative"){
      let company: any = {} 
      company.address = data.companyAddress;
      company.email =data.companyEmail
      company.name =data.companyName
      company.phone =data.companyPhone
      company.sector =data.companySector

      data.Company = company;

    }
    const response: any = await updateProfile(data);
    let msgObj: any = {};
    if (response.error) {
      msgObj.status = "error";
      msgObj.data = response.error.data;
      setMsg(msgObj);
    } else {
      setSuccess(true)
      msgObj.status = "success";
      msgObj.data = "Profile updated successfuly!";
      setMsg(msgObj);
      setOpenDialog(true);
      setDialogMessage(response.data.toString());
    }
  };
  return (
    <Container>
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {msg.status && (
          <Box>
            <Alert severity={msg.status}>{msg.data}</Alert>
            <br />
          </Box>
        )}
        <Typography variant="h2" gutterBottom>
          Update Profile
        </Typography>
        <Box
          component="form"
          id="formuser"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          mx={5}
        >
          {user && (
            <>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              label="First name"
              fullWidth
              variant="standard"
              {...register("firstName", { required: true })}
              error={Boolean(errors.firstName)}
              defaultValue={user?.firstName || ""}
              helperText={errors.firstName && "First name is required"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              label="Last name"
              fullWidth
              variant="standard"
              defaultValue={user?.lastName || ""}
              {...register("lastName", { required: true, maxLength: 20 })}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && "Last name is required"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="Email"
              fullWidth
              variant="standard"
              defaultValue={user?.email || ""}
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              error={Boolean(errors.email)}
              helperText={errors.email && "Please enter a valid email address"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="tel"
              label="Phone Number"
              fullWidth
              variant="standard"
              {...register("tel", {
                required: "This input is required.",
                pattern: /^[0-9]{8}$/,
              })}
              defaultValue={user?.tel || ""}
              error={Boolean(errors.tel)}
              helperText={
                errors.tel && "Phone number must contain exactly 8 numbers"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="username"
              label="Username"
              fullWidth
              variant="standard"
              defaultValue={user?.username || ""}
              {...register("username", {
                required: true,
                pattern: /^[a-zA-Z0-9_@\.]+$/,
              })}
              error={Boolean(errors.username)}
              helperText={
                errors.username &&
                "Username can only contain letters, numbers underscore symbol (_), @ symbol, and period (.)"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="role"
              label="Role"
              fullWidth
              variant="standard"
              defaultValue={user?.Role.name || ""}
              disabled={true}
            />
          </Grid>

          {role === "company representative" && (
            <>
            <Typography> Company Details:</Typography>
            <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="Company"
              fullWidth
              variant="standard"
              defaultValue={user?.Company?.name || ""}
              {...register("companyName", { required: true, maxLength: 20 })}
              error={Boolean(errors.companyName)}
              helperText={errors.companyName && "company is required"}
            />
          </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
                required
                id="email"
                label="Email"
                fullWidth
                variant="standard"
                defaultValue={user?.Company?.email || ""}
                {...register("companyEmail", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={Boolean(errors.email)}
                helperText={errors.email && "Please enter a valid email address"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="companyPhone"
                label="Phone"
                fullWidth
                variant="standard"
                defaultValue={user?.Company?.phone || ""}
                {...register("companyPhone", {
                  required: true,
                  pattern: /^[0-9]{8}$/,
              })}
              error={Boolean(errors.companyPhone)}
              helperText={
                errors.companyPhone && "Phone number must contain exactly 8 numbers"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              required
              id="sector"
              label="Sector"
              fullWidth
              variant="standard"
              defaultValue={user?.Company?.sector || ""}
              {...register("companySector", { required: true, maxLength: 20 })}
              error={Boolean(errors.companySector)}
              helperText={errors.companySector && "company sector is required"}
            />
          </Grid>
            </>
          )}

          <Grid container justifyContent="flex-end">
            <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
              Update
            </Button>{" "}
          </Grid>
          </>
          )}
        </Box>
      </Paper>
      <Dialog
        open={success}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Profile updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate("/profile", { replace: true })}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
