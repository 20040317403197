import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "../redux/api/authApi";
import { Alert } from "@mui/material";
import { useState } from "react";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [err, setErr] = useState();
  const [success, setSuccess] = useState();
  const [forgotPassword] = useForgotPasswordMutation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response: any = await forgotPassword(data);
    if (response.data) {
      setSuccess(response.data);
      setErr(undefined)
    } else {
      setErr(response.error.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 3,
            }}
          >
            {err && (
              <Box>
                <Alert severity="error">{err}</Alert>
                <br />
              </Box>
            )}
            {success && (
              <Box>
                <Alert severity="success">{success}</Alert>
                <br />
              </Box>
            )}
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockResetIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recover your password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                required
                id="email"
                label="Email"
                fullWidth
                variant="standard"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={Boolean(errors.email)}
                helperText={
                  errors.email && "Please enter a valid email address"
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                Send email
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    Don't have an account?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}
