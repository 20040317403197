import { Box, Container } from "@mui/material";
import ActionHistoryComponent from "../components/actionHistory";


export default function ActionHistoryPage(){
    return(
        <Box>
            <Container>
                <br/>
            
                <ActionHistoryComponent/>

            </Container>
            
        </Box>
        
    )
}