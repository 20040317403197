import { Box, Paper, Typography } from "@mui/material";
import RequestsList from "../components/requests/requestsList";


export default function PublicRequestsPage(){
    return(
        <Box>
                <br/>
            
            <Paper elevation={3} sx={{ my: { xs: 2, md: 1 }, p: { xs: 2, md: 3 } , maxWidth:"1250px", margin: 'auto' }}>
            <Typography component="h2" variant="h2" align="center">
                Requests
            </Typography>
                <RequestsList/>
            </Paper>
            
        </Box>
        
    )
}