import React from 'react';
import LinkedInProfileComponent from './linkedInProfile';
import LinkedInSkillsComponent from './linkedInSkills';
import LinkedInEducationComponent from './linkedInEducation';
import LinkedInExperienceComponent from './linkedInExperience';
import Button from '@mui/material/Button';

const ProfileListComponent: React.FC<any> = ({
  profiles,
  itemsPerPage,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);

  // Calculate the indexes of the profiles to be displayed on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProfiles = profiles.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div>
      {currentProfiles.map((profile: any, index: any) => (
        <React.Fragment key={index}>
            
          <LinkedInProfileComponent profile={profile} />
          <LinkedInEducationComponent education={profile.education} />
          <LinkedInExperienceComponent experiences={profile.experiences} />
          <LinkedInSkillsComponent skills={profile.skills} />
        </React.Fragment>
      ))}

      {/* Pagination */}
      <div>
        <br/>
        <br/>
        {Array.from({ length: Math.ceil(profiles.length / itemsPerPage) }, (_, index) => (
          <Button
            key={index}
            variant={currentPage === index + 1 ? 'contained' : 'outlined'}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ProfileListComponent;
