import { Typography, Skeleton, Paper, Grid } from "@mui/material";
import randomColor from "randomcolor";
import { useGetSectorCountQuery } from "../redux/api/companyApi";
import { Bar, Doughnut } from "react-chartjs-2";
import { useGetcategoryWithRequestCountQuery } from "../redux/api/categoryApi";
import theme from "../shared/theme/theme";

export default function AdminCharts() {
  /** Sector count */
  const {
    data: sectorCount,
    isLoading: isLoadingSectorCount,
    isFetching: isFetchingSectorCount,
  } = useGetSectorCountQuery();
  const sectors: any = sectorCount?.map((item: any) => item?.sector) || [];
  const sectorsOcc: any = sectorCount?.map((item: any) => item?._count?.sector);
  /** Request Count per category */
  const {
    data: RequestCount,
    isLoading: isLoadingRequestCount,
    isFetching: isFetchingRequestCount,
  } = useGetcategoryWithRequestCountQuery();
  if (
    isLoadingSectorCount ||
    isFetchingRequestCount ||
    isFetchingSectorCount ||
    isLoadingRequestCount
  ) {
    return (
      <div>
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
        <br />
        <Skeleton variant="rectangular" height={15} />
      </div>
    );
  }

  // Generate dynamic colours
  const coloursSectorCount = randomColor({ count: sectors.length });

  /** Doughnut data */
  const sectorsChartData = {
    labels: sectors,
    datasets: [
      {
        data: sectorsOcc,
        backgroundColor: coloursSectorCount,
      },
    ],
  };

  const categories: any = RequestCount?.map((item) => item.name) || [];
  const categoriesOcc: number[] =
    RequestCount?.map((item) => item?._count?.requests) || [];
  //console.log("req",RequestCount)

  /** Bar data */
  const reqChartData = {
    labels: categories,
    datasets: [
      {
        label: "categories",
        data: categoriesOcc,
        backgroundColor: theme.palette.secondary.main,
      },
    ],
  };
  // reqChartData.datasets[0].data.sort((a, b) => b - a);
  const options = {
    //    responsive: true,
    //    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      ],
    },
  } as any;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              align="center"
              color="primary"
              fontWeight={500}
            >
              Number of Requests per Category
            </Typography>
            <Bar data={reqChartData} options={options} />
          </Paper>
        </Grid>
        <br />
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              align="center"
              color="primary"
              fontWeight={500}
            >
              Number of Companies per Sectors
            </Typography>
            <Doughnut data={sectorsChartData} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
