import { getAccessTokenFromLocalStorage, getRoleFromLocalStorage } from "../core/services/localStorage.service";
import { useDispatch } from "react-redux";
import { logOut, selectIsLogged,  } from "../redux/slices/authSlice";
import { useAppSelector } from "../redux/hooks";

export const useLoggedIn = (): boolean => {
  const isLogged = useAppSelector(selectIsLogged);
  const dispatch = useDispatch();
  let token = getAccessTokenFromLocalStorage();
   
  if (!token || token ==='' || isLogged === false ) {
    dispatch(logOut())
  }
  return isLogged;
};
export const useRole = (): string | null => {
  //const role = useAppSelector(selectRole);
  const dispatch = useDispatch();
  let userRole = getRoleFromLocalStorage();
   
  if (!userRole || userRole ==='' ) {
    dispatch(logOut())
  }
  return userRole;
};
