import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import AddUserForm from "../components/addUserForm";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";

export default function CreateUserPage() {
  let navigate = useNavigate()
  return (
    <Box>
      <Container>
        <br />
        <Breadcrumbs aria-label="breadcrumb" separator="›"> userslist
          <Link underline="hover" color="inherit"  onClick={()=>navigate("/userslist")}>
            <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Users List
          </Link>

          <Typography>
            <PersonAddAltIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Create User
          </Typography>
        </Breadcrumbs>

        <AddUserForm />
      </Container>
    </Box>
  );
}
