export const CONFIG = {
    BASE_URL_API: process.env.REACT_APP_API_URL|| '',
    ENDPOINTS: {
        auth : {
            signup: "/auth/register",
            resetPassword:"/auth/resetPassword",
            resetPasswordEmail:"/auth/resetPassword/",
            login:"/auth/login",
            refresh:"/auth/refreshToken"
        },
        role : {
            getAll: "/role",
            create: "/role/create",
            update: "/role/update",
            delete: "/role/delete"
        },
        category : {
            getAll: "/category/all",
            create: "/category/create",
            update: "/category/update/",
            delete: "/category/delete/",
            getCount: "/category/categoryWithRequestCount"
        },
        admin : {
            getAllCompanies: "/admin/getAllCompanies",
            deleteUser: "/admin/deleteUser/",
            updateUser: "/admin/updateUser/",
            deleteUsers: "/admin/deleteUsers",
            createUser: "/admin/createUser",
            getAllUsers: "/admin/getAllUsers",
            getUser: "/admin/userById",
            getActionHistoryByUser :"/admin/actionHistoryByUserId/",
            getSectorCount : "/admin/getSectorsCount",
            getTopTech:"/admin/getTopTech",
            getUsersRoleCount:"/admin/getUsersRoleCount",
        },
        users : {
            updatePassword: "/users/updatePassword",
            profile: "/users/profile",
            updateProfile: "/users/updateProfile",
            
        },
        request : {
            getNotAssigned: "/request/notAssigned",
            getAssignedToLoggedInUser: "/request/assignedTo",
            setState: "/request/setRequestState/",
            getRequestsByUser: "/request/byuser/",
            assignRequest: "/request/assignRequest/",
            setSelectors: "/request/setSelectors/",
            deleteRequest: "/request/deleteRequest/",
            getRequestById: "/request/request/",
            updateRequest: "/request/updateRequest/",
            getAllPending: "/request/pending",
            createRequest: "/request/createRequest",
            getAllFailed: "/request/failed",
            countFailed: "/request/countFailed",
            countAll :"/request/countAll",
            countPending: "/request/countPending",
            countUnassignedRequests: "/request/countUnassigned",
            countMyRequestsByState: "/request/countMyRequestsByState",
            countMyAssignedRequests: "/request/countMyAssignedRequests",

        },
        data : {
            getData: "/data/getData/",
            getCleanData: "/data/getCleanData/",
            create: "/data/createCleanData",
            getCleanDataByReq: "/data/getCleanDataByRequest/",
            deleteCleanDataByReq: "/data/deleteCleanData/",
            getCleanDataBySubject: "/data/getCleanDataBySubject/"
        }
    },
    DASHBOARD : {
        generalStats: "general stats",
        InterestRate: "Interest rate in % (predicted)",
        AverageExchangeRate: "Average exchange rate (predicted)",
        Annual_glissement: "Annual glissement of real estate prices by property type in % (predicted)",
        RealEstatePrices:"Real estate prices by property type (predicted)"
    }
}