import datahunt from "../assets/datAHuntLogo.png";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { useLoggedIn } from "../hooks/authHooks";
import { Fragment } from "react";
import AdminMenu from "./adminMenu";
import { useGetProfileQuery } from "../redux/api/usersApi";
import TechnicianMenu from "./technicianMenu";
import CompanyRepMenu from "./companyRepMenu";
import { Button } from "@mui/material";

export default function Header() {
  const isLoggedIn = useLoggedIn();
  const { data: user} = useGetProfileQuery();
  return (
    <>
      <AppBar
        position="absolute"
        color="primary"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {(isLoggedIn && user?.Role.name === "administrator" ) &&
           
            <Fragment>
              <AdminMenu/>
            </Fragment>
            
            }
            {(isLoggedIn && user?.Role.name === "technician" ) &&
           
           <Fragment>
             <TechnicianMenu/>
           </Fragment>
           
           }

          {(isLoggedIn && user?.Role.name === "company representative" ) &&
           
           <Fragment>
             <CompanyRepMenu/>
           </Fragment>
           
          }
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button href="/">
              <img
                src={datahunt}
                alt="datahunt logo"
                width={100}
                className="round-image"
              /></Button>
              <Typography color="inherit" align="center">
                <em>Simplify your data journey</em> 
              </Typography>
              
            </div>
          </div>
        </div>
      </AppBar>
    </>
  );
}
