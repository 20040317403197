export enum RouteIdEnum {
  Root = '/',
  Any = '*',
  Home = 'home',
  Login ='login',
  SignUp = 'signup',
  ForgotPassword = 'forgotpassword',
  ResetPassword = 'resetpassword/*',
  Roles = 'roles',
  Categories = 'categories',
  Companies = 'companies',
  CreateUser = 'createuser',
  UsersList = 'userslist',
  UpdateUser = 'updateuser/*',
  UpdateProfile = 'updateprofile/*',
  CreateRequest = 'createrequest',
  RequestsList = 'requestslist',
  UpdateRequest = 'updaterequest/*',
  RequestDetails = 'requestDetails/*',
  ChangePassword = 'changePassword',
  Profile = 'profile',
  ActionHistory = 'actionHistory/*',
  PendingRequests = 'pendingRequests',
  FailedRequests = 'failedRequests',
  UnassignededRequests = 'unassignedRequests',
  assignedToMe = 'assignedToMe',
  linkedInRequests = 'linkedInRequests',
  scrapeLinkedIn = "scrapeLinkedIn",
  demo = "demo",
  stats = "generalStats",
  adminDashboard = "adminDashboard",
  technicianDashboard = "technicianDashboard"
}
