import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../api/authApi";
import { getAccessTokenFromLocalStorage, saveAccessTokenToLocalStorage, saveRefreshTokenToLocalStorage, saveRoleToLocalStorage, saveUserToLocalStorage } from "../../core/services/localStorage.service";
const token = getAccessTokenFromLocalStorage() ;
let logged=false;
if(token){
  logged=true
}
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    role: null,
    accessToken: null,
    refreshToken: null,
    //isLogged: false,
    isLogged: logged,
  },
  reducers: {
    login: (state, action) => {
      const data = action.payload;
      state.user = data.existingUser;
      state.role = data.existingUser.Role.name;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;
      state.isLogged = true;
    },
    
    
    logOut: (state) => {
      state.user = null;
      state.role = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isLogged = false;
      localStorage.clear();

    },
    signUp: (state, action) => {
      const data = action.payload;
      state.user = data.user;
      state.role = data.user.Role.name;
      state.accessToken = data.accessToken;
      state.refreshToken = data.refreshToken;
      state.isLogged = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signUp.matchFulfilled,
      (state, { payload }) => {
        if (payload.accessToken) {
          saveAccessTokenToLocalStorage(payload.accessToken);
          state.isLogged = true;
          saveRefreshTokenToLocalStorage(payload.refreshToken);
          saveUserToLocalStorage(payload.user.id); 
          saveRoleToLocalStorage(payload.user.Role.name) 
        }
      }
    );
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.accessToken) {
          saveAccessTokenToLocalStorage(payload.accessToken);
          state.isLogged = true;
          saveRefreshTokenToLocalStorage(payload.refreshToken);   
          saveUserToLocalStorage(payload.existingUser.id)  
          saveRoleToLocalStorage(payload.existingUser.Role.name)       
        }
      }
    );
  },
});

export const { login, logOut, signUp } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectIsLogged = (state: any) => state.auth.isLogged;
export const selectRole = (state: any) => state.auth.role;
export const selectCurrentAccessToken = (state: any) => state.auth.accessToken;
export const selectCurrentRefreshToken = (state: any) => state.auth.refreshToken;
