import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "../redux/api/authApi";
import { Alert } from "@mui/material";
import { RouteIdEnum } from '../core/enums/routes.enum';
//import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppDispatch } from "../redux/hooks";
import Loader from "./Loader/Loader";
import { login } from "../redux/slices/authSlice";
import {  useState } from "react";
import { useNavigate } from 'react-router-dom';


export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [err, setErr] = useState();
  const [login1, {isLoading}] = useLoginMutation()
 // const auth = useSelector(((state: RootState) => state.auth))
 // const user = useSelector((state: RootState)  => state.auth.user)
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const onSubmit = async (data: any) => {
    const response: any = await login1(data)
    if(response.error){
      setErr(response.error.data);
    }
    else{
  dispatch(login(response.data))
  navigate(`/profile`);
  
    }
    
  };
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 3,
            }}
          >
            { err && 
            <Box>
              <Alert severity="error">{err}</Alert>
              <br/>
            </Box>
          }
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight={500}>
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                autoComplete="login"
                autoFocus
                {...register("login", {
                  required: true,
                })}
                error={Boolean(errors.login)}
                helperText={
                  errors.login && "Please enter a valid login"
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                {...register("password", { required: true })}
                error={Boolean(errors.password)}
                helperText={errors.password && "Password is required"}
                /* InputProps={{ // Add icon button to toggle visibility
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )
                }} */
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}

              >
                 {isLoading? <Loader/> : <Typography>Sign In</Typography>}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href={RouteIdEnum.ForgotPassword} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    Don't have an account?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}
