import {  Typography } from "@mui/material";
import Stats from "../components/generalStats";
import ExchangeRates from "../components/exchangeRate/exchangeRate";
import FinanceChart from "../components/finance";
import RealEstateChart from "../components/realEstate";

export function GeneralStatsPage(){
    return(
        <>
        <br/>
        <ExchangeRates/>
        <Typography component="h2" variant="h2" align="center" color={"white"}>
                General Statistics
            </Typography>
            <br/>
        <Stats/>

            <FinanceChart/>

        <RealEstateChart/>
        </>
    )
}