
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


export default function Footer() {

  return (
    <Typography variant="body2" color="white" align="center">
      <br/>
      {'Copyright © '}
      <Link color="inherit" href="https://anypli.com/fr_fr/">
        Anypli
      </Link>{' '}
      2023
      {'.'}
      <br/>
      <br/>
    </Typography>
  );
}