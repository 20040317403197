import {
    Card,
    CardContent,
    Typography,
    Skeleton,
    CardMedia,
    Box
  } from "@mui/material";
  import top from "../assets/dasboard/top.png";
  import companyRep from "../assets/dasboard/company representative.png";
  import technicianImage from "../assets/dasboard/technicianImage.png";
  
  import allImage from "../assets/dasboard/allImage.png";
  import failedImage from "../assets/dasboard/failedImage.png";
  import pendingImage from "../assets/dasboard/pendingImage.png";
  import {
    useGetCountAllQuery,
    useGetCountFailedQuery,
    useGetCountPendingQuery,
  } from "../redux/api/requestsApi";
import { useGetTopTechQuery, useGetUsersRoleCountQuery } from "../redux/api/usersApi";
  
  export default function AdminCards() {
    const {
        data: topTech,
        isLoading: isTopTechLoading,
        isFetching: isTopTechFetching,
      } = useGetTopTechQuery();
      const {
        data: UsersRoleCount,
        isLoading: isUsersRoleCountLoading,
        isFetching: isUsersRoleCountFetching,
      } = useGetUsersRoleCountQuery();
      
    const {
      data: all,
      isLoading: isAllLoading,
      isFetching: isAllFetching,
    } = useGetCountAllQuery();
    const {
      data: pending,
      isLoading: isPendingLoading,
      isFetching: isPendingFetching,
    } = useGetCountPendingQuery();
    const {
      data: failed,
      isLoading: isFailedLoading,
      isFetching: isFailedFetching,
    } = useGetCountFailedQuery();
   
    let data = [
      {
        title: "Total Requests",
        value: all,
        color: "success.main",
        image: allImage,
      },
      {
        title: "Pending ...",
        value: pending,
        color: "warning.main",
        image: pendingImage,
      },
      {
        title: "Failed Requests",
        value: failed,
        color: "error",
        image: failedImage,
      },
      {
        title: "Top Technician",
        value: topTech?.firstName + " " + topTech?.lastName,
        color: "secondary.main",
        image: top,
      },
    ];

    UsersRoleCount?.map((item: any) => {
        let img;
        if(item.name !== "administrator"){
            
            if(item.name === "technician"){
                img= technicianImage
            } else {
                img= companyRep
            }

            data.push({
         title: item.name, 
      value: item._count.users,
      color: "primary.main",
      image:img 
    }
    )}} )
  

    if (
      isAllLoading ||
      isAllFetching ||
      isPendingLoading ||
      isPendingFetching ||
      isFailedLoading || isUsersRoleCountLoading|| isUsersRoleCountFetching||
      isFailedFetching || isTopTechLoading || isTopTechFetching
    ) {
      return (
        <div>
          <br />
          <Skeleton variant="rectangular" height={15} />
          <br />
          <Skeleton variant="rectangular" height={15} />
          <br />
          <Skeleton variant="rectangular" height={15} />
        </div>
      );
    }
  
  
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap"  }}>
        {data?.map((item: any, index: any) => (
          <Card
            key={index}
            sx={{
              width: 165,
              height: 200,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              margin: 1
            }}
          >
            <CardContent>
              {/* Card content */}
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title.toLowerCase()}
                sx={{ height: 80, width: 80, marginLeft: 3 }}
              />
  
              <br />
              <Typography color={item.color} fontWeight="bold">
                {item.title.toString().toUpperCase()}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                fontWeight="bold"
                color={item.color}
              >
                {item.value}
              </Typography>
              
             {item.title === "Top Technician" && (
                <Typography
                variant="body2"
                component="p"
                color={"primary"}
              >
                Total Requests: {topTech._count.assignedReq}
            </Typography>
             )}
              
               
            </CardContent>
          </Card>
        ))}
        
        </Box>
    );
  }
  