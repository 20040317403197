import { ReactNode } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
//import { Navigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

function AuthGuard(props: Props) {
  const { children } = props;
  const isLogged  = useAppSelector((state: RootState) => state.auth.isLogged);
  if(isLogged)
    return <>{children}</>;
  else
    return <Navigate to="/" /> 
}

export default AuthGuard;
