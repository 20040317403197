
export const saveAccessTokenToLocalStorage = (token: any) => {
    localStorage.setItem("accessToken", token);
};

export const saveRefreshTokenToLocalStorage = (token: any) => {
    localStorage.setItem("refreshToken", token);
};

export const saveRoleToLocalStorage = (role: any) => {
    localStorage.setItem("role", role);
};

export const getAccessTokenFromLocalStorage = () => {
    return localStorage.getItem("accessToken");
};

export const saveUserToLocalStorage = (user: any) => {
    localStorage.setItem("user", user);
};

export const getUserFromLocalStorage = () => {
    return localStorage.getItem("user");
};
export const getRoleFromLocalStorage = () => {
    return localStorage.getItem("role");
};

export const getRefreshTokenFromLocalStorage = () => {
    return localStorage.getItem("refreshToken");
};
  
  