import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import Loader from "./Loader/Loader";
import {  useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useCreateRequestMutation } from "../redux/api/requestsApi";
import { Alert} from "@mui/material";


export default function ScrapeLinkedin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [err, setErr] = useState();
  const [createRequest, {isLoading}] =  useCreateRequestMutation()
  let navigate = useNavigate();
  const onSubmit = async (data: any) => {

    let body = {"website" :{
      "googleQuery":  `site:linkedin.com/in/ AND \\"`+data.place+`\\" AND \\"`+data.job+`\\"\\"`

	}}

    //console.log(body.website.googleQuery)
    const response: any = await createRequest(body);
    if(response.error){
        setErr(response.error.data);
        
      }
      else{
       // console.log("response:" , response)
    navigate(`/requestDetails/`+response.data.id);
    
      } 
    
  };
  return (
    <div>
      <Container component="main" maxWidth="xs">
      
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 3,
            }}
          >
           { err && 
            <Box>
              <Alert severity="error">{err}</Alert>
              <br/>
            </Box>
          } 
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LinkedInIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Scrape LinkedIn
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="place"
                label="Country/Place"
                autoComplete="place"
                autoFocus
                {...register("place", {
                  required: true,
                })}
                error={Boolean(errors.login)}
                helperText={
                  errors.place && "This field is required"
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="job"
                label="Job"
                autoComplete="job"
                autoFocus
                {...register("job", {
                  required: true,
                })}
                error={Boolean(errors.login)}
                helperText={
                  errors.job && "This field is required"
                }
              />
              {isLoading? (
              <>
              <Typography color={"primary"}>This Operation will take some time please wait</Typography>
              <Loader/>
              </>)  : (
                <>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}

              >
               <Typography>Scrape</Typography>
              </Button>
              </>
              )
              }
              
              
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}
