import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import RequestDetails from "../components/requests/requestDetails";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";
import { useRole } from "../hooks/authHooks";

export default function RequestDetailsPage(){
    let navigate = useNavigate()
    let role = useRole();
    return(
        <Box>
            <Container>
                <br/>
            { role === "administrator" &&(
                <Breadcrumbs aria-label="breadcrumb" separator="›"> userslist
                <Link underline="hover" color="inherit"  onClick={()=>navigate("/requestslist")}>
                    <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Requests List
                </Link>
                <Typography>
                    <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                     Request Details
                </Typography>
            </Breadcrumbs> 
            ) }
            {/* { role === "technician" &&(
                <Breadcrumbs aria-label="breadcrumb" separator="›"> userslist
                <Link underline="hover" color="inherit"  onClick={()=>navigate("/assignedToMe")}>
                    <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    My Assigned Requests
                </Link>
                <Typography>
                    <InfoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                     Request Details
                </Typography>
            </Breadcrumbs> 
            ) } */}
        
            
                <RequestDetails/>

            </Container>
            
        </Box>
        
    )
}