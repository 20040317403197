import { Box, Breadcrumbs, Container, Link, Typography } from "@mui/material";
import UpdateUserForm from "../components/updateUser";
import ListIcon from "@mui/icons-material/List";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from "react-router-dom";

export default function UpdateUserPage() {
  let navigate= useNavigate()
  return (
    <Box>
      <Container>
        <br />
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link underline="hover" color="inherit" onClick={()=>navigate("/userslist")}>
            <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Users List
          </Link>

          <Typography>
            <ManageAccountsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Update User
          </Typography>
        </Breadcrumbs>

        <UpdateUserForm />
      </Container>
    </Box>
  );
}
