import { Box, Container, Paper, Typography } from "@mui/material";
import UsersList from "../components/users/usersList";


export default function UsersListPage(){
    return(
        <Box>
            <Container>
                <br/>
            
            <Paper elevation={3} sx={{ my: { xs: 4, md: 8 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h2" variant="h2" align="center">
                Users
            </Typography>
                <UsersList/>
            </Paper>
            </Container>
            
        </Box>
        
    )
}