import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Container, Grid, Link, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetRequestQuery, useUpdateRequestMutation } from "../../redux/api/requestsApi";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import { Field } from "./requests.types";
import { Category } from "../../core/models/types";
import Loader from "../Loader/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}; 

export default function UpdatePrivateRequest() {
    const location = window.location.href;
    const locationParts = location.split("/");
    const id = locationParts[locationParts.length - 1]; // get second-to-last element

    const { data: existingReq } = useGetRequestQuery(id);

    const [selectedOption, setSelectedOption] = useState('table');
    const [reqTitle, setReqTitle] = useState(existingReq?.title);
    const [url, setUrl] = useState(existingReq?.website?.url ?? '');
    const [tableSelector, setTableSelector] = useState(existingReq?.website?.table?.selector);
    const [header, setHeader] = useState(existingReq?.website?.table?.header);
    const [body, setBody] = useState(existingReq?.website?.table?.body);
    const [title, setTitle] = useState(existingReq?.website?.table?.title);
    const [unit, setUnit] = useState(existingReq?.website?.table?.unit ?? '');

    const [areaSelector, setAreaSelector] = useState(existingReq?.website?.selector || "");
    const [fields, setFields] = useState<Field[]>(existingReq?.website?.fields || [{ name: "", selector: "" }]);
    const [categories, setCategories] = useState<Category[] | undefined>(existingReq?.categories);
    const [categoryName, setCategoryName] = useState<String[]>(categories?.map(cat => cat.name) || []);
    const [needTech, setNeedTech] = useState(false);
    const [description, setDescription] = useState<String>("");
    const [msg, setMsg] = useState({ status: undefined, data: null });
    
    useEffect(() => {    
        if (existingReq) {
            setReqTitle(existingReq?.title)
            setUrl(existingReq?.website?.url)
            setCategoryName(existingReq?.categories?.map(cat => cat.name))
            setCategories(existingReq?.categories)
                setDescription(existingReq?.description)
            
            setNeedTech(existingReq?.needTech)

              if(existingReq?.website?.table){
              setTableSelector(existingReq?.website?.table?.selector)
              setHeader(existingReq?.website?.table?.header)
              setBody(existingReq?.website?.table?.body)
              setTitle(existingReq?.website?.table?.title)
              setUnit(existingReq?.website?.table?.unit)
              setSelectedOption('table')
            } else if(existingReq?.website?.fields) {
              setAreaSelector(existingReq?.website?.selector)
              setFields(existingReq?.website?.fields)
              setSelectedOption('fields')
            }   
            
                
    
        }
        }, [existingReq]);

        let navigate = useNavigate()
    const [UpdateRequest, {isLoading}] = useUpdateRequestMutation();

    const {
        data: category
    } = useGetCategoriesQuery();

    const sortedCategories = category
    ? [...category].sort((a, b) => a.name.localeCompare(b.name.toString()))
    : [];

    const {
        handleSubmit,
        reset,
    } = useForm();

  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event: any) => {
    setNeedTech(JSON.parse(event.target.value));
  };

  const handleAddField = () => {
    fields ? (setFields([...fields, { name: '', selector: '' }])): setFields([{ name: '', selector: '' }]);    
};

const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, field: keyof Field) => {
  const updatedFields = [...fields];
  const updatedField = {
    ...updatedFields[index],
    [field]: event.target.value,
  };
  updatedFields[index] = updatedField;
  setFields(updatedFields);
};


  const onSubmit = async () => {
    const selectedCategoryIds = category?.filter((c) => categoryName.includes(c.name.toString())).map((c) => c.id);
    let msgObj: any = {};
    let data
    let response : any = {}
   
    if(needTech){
        data = {
            id : id,
          website: { url},
          title : reqTitle,
          description : description,
          needTech: needTech,
        };
        response = await UpdateRequest(data);
        
    } else if (selectedOption === "table") {
      data = {
        id : id,
        website: {
          url,
          table: { selector: tableSelector, header: header, body, title, unit },
        },
        title : reqTitle,
        needTech: needTech,
        categoryIDs: selectedCategoryIds,
      };
       response = await UpdateRequest(data);
      
    } else {
       data = {
        id : id,
        website: { url, selector: areaSelector, fields },
        title : reqTitle,
        categoryIDs: selectedCategoryIds,
        needTech: needTech
      };
       response = await UpdateRequest(data);
      
    }
    if (response?.data) {
        msgObj.status = "success";
        msgObj.data = "request updated successfuly!";
        setMsg(msgObj);
        reset();
        navigate('/requestslist')
      } else {
        msgObj.status = "error";
        if (response.error.data.message)
          msgObj.data = response.error.data.message;
        else msgObj.data = response.error.data;
        setMsg(msgObj);
      }
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {msg.status && (
          <Box>
            <Alert severity={msg.status}>{msg.data}</Alert>
            <br />
          </Box>
        )}
        <Typography component="h1" variant="h2" align="center">
          Update Request
        </Typography>
        <br />
        {existingReq && (
            <>
        <form id="requestForm" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField variant="standard" label="URL"  onChange={(event) => setUrl(event.target.value)} required value={''+url}  />
          <TextField variant="standard" label="Request Title"  onChange={(event) => setReqTitle(event.target.value)} required value={''+reqTitle}  />
        
                <br/>

                <RadioGroup
              sx={{ display: "flex", alignItems: "center" }}
              row
              value={needTech}
              onChange={handleOptionChange1}
            >
              <InputLabel sx={{ marginRight: "16px", color:"black" }}>Do you need a technician? </InputLabel>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {needTech === true ? (
              <TextField
                variant="standard"
                label="Description"
                defaultValue={description}
                multiline
                onChange={(event) => setDescription(event.target.value)}
                required
              />
            ) : null}
            <RadioGroup row
          aria-label="request-type"
          name="request-type"
          value={selectedOption.toString()}
          onChange={handleOptionChange}
        >
          <FormControlLabel value="table" control={<Radio />} label="Table" disabled={needTech} />
          <FormControlLabel value="fields" control={<Radio />} label="Fields" disabled={needTech}/>
          
        </RadioGroup>

            {selectedOption === "table" ? (
              <>
                <TextField
                  variant="standard"
                  label="Table Selector"
                  defaultValue={tableSelector}
                  onChange={(event) => setTableSelector(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Header"
                  defaultValue={header}
                  onChange={(event) => setHeader(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Body"
                  defaultValue={body}
                  onChange={(event) => setBody(event.target.value)}
                  required
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Title"
                  defaultValue={title}
                  onChange={(event) => setTitle(event.target.value)}
                  disabled={needTech}
                />
                <TextField
                  variant="standard"
                  label="Unit"
                  defaultValue={unit}
                  onChange={(event) => setUnit(event.target.value)}
                  disabled={needTech}
                />
              </>
            ) : (
              <>
           {/*  <TextField variant="standard" label="URL" defaultValue={url} onChange={(event) => setUrl(event.target.value)} required />
           */}  <TextField variant="standard" label="Area Selector" //defaultValue={areaSelector} 
           onChange={(event) => setAreaSelector(event.target.value)} required  value={''+areaSelector} disabled={needTech} />
            <br/>
            <Typography variant="h6" style={{textDecoration: 'underline', textAlign: 'left'}}>Fields:</Typography>
            {fields && fields.map((field, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField disabled={needTech} variant="standard" label="Name" fullWidth defaultValue={field.name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event, index, 'name')} required />
               </Grid> 
               <Grid item xs={12} sm={6}>
               <TextField disabled={needTech} variant="standard" label="Selector" fullWidth defaultValue={field.selector} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event, index, 'selector')} required />
              </Grid>
              </Grid>
              </Box>
            ))}
            <br/>
            <Grid style={{textAlign: 'left'}}>
            <Link component="button" sx={{ mt: 3, ml: 1 }} onClick={handleAddField} disabled={needTech} >Add Field</Link>
            {fields?.length > 1 && (
                <Link component="button" disabled={needTech} sx={{ mt: 3, ml: 3 }} onClick={() => setFields(fields.slice(0, -1))}>Remove Field</Link>
              )}
              </Grid>
          </>
            )}
            <br />
            <Grid style={{ textAlign: "left" }}>
              <InputLabel>Categories</InputLabel>
              <Select
                label="Categories"
                id="categories"
                disabled={needTech}
                multiple
                value={categoryName}
                fullWidth
                onChange={handleChange}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Category" disabled={needTech} />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected?.map((value) => (
                      <Chip key={value.toString()} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {sortedCategories?.map((cat) => (
                  <MenuItem key={cat.id.toString()} value={cat.name.toString()}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              {isLoading? (
              <>
              <Typography color={"primary"}>This Operation will take some time please wait</Typography>
              <Loader/>
              </>)  : (
                <>
                <Button
                onClick={() => navigate("/requestslist")}
                sx={{ mt: 3, ml: 1 }}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
                Submit
              </Button></>
              )  }
              
            </Grid>
          </Box>
        </form>
        </>)}
      </Paper>
    </Container>
  );
}
