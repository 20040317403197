import { Container, Typography } from "@mui/material";
import TechnicianDashboard from "../components/technicianDashboard";

export default function TechnicianDashboardPage(){
    return(
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container >
            <br/>
          <Typography component="h2" variant="h2" align="center" color="white">
            My Dashboard
          </Typography>
          <hr />
          <br/>
          <TechnicianDashboard />
        </Container>
      </Container>
      
        
    )
}