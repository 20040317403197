import { useGetActionHistoryByUserQuery } from "../redux/api/actionHistoryApi";
import {
    Container,
  Paper,
  Skeleton,
  TableContainer,
} from "@mui/material";
import moment from "moment";

import DataTable from "react-data-table-component";
import { useAppSelector } from "../redux/hooks";
import { selectCurrentUser } from "../redux/slices/authSlice";
import { useGetUserQuery } from "../redux/api/usersApi";
import {  Breadcrumbs, Link, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListIcon from "@mui/icons-material/List";
import {  useNavigate } from "react-router-dom";
import { getUserFromLocalStorage } from "../core/services/localStorage.service";

export default function ActionHistoryComponent() {
  const url = window.location.href;
  const urlParts = url.split("/");
  const id = urlParts[urlParts.length - 1]; // get second-to-last element
  let navigate = useNavigate()
  const{data : user}  = useGetUserQuery(id)
  const currentUser = useAppSelector(selectCurrentUser)
  let myId;
  if(currentUser){
    myId=currentUser.id
  }else{
    myId=getUserFromLocalStorage()
  }

  let myHistory : boolean;
  if(myId === id){
    myHistory=true
  }
  else {
    myHistory=false
  }

  let title =""
  if(myId=== id){
    title="My Action History"
  } else{
    title=`${user?.username}'s Action History`;
  }
  
  const {
    data: actionHistory,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetActionHistoryByUserQuery(id);

  if (isLoading || isFetching) {
    return <div> 
      <TableContainer component={Paper}>
      <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
      </TableContainer>  
    
  </div>;
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!actionHistory) {
    return <div>No DATA ! </div>;
  }

  const columns = [
    {
      name: (
        <div>
          <h2>Action</h2>
        </div>
      ),
      selector: (row: any) => row.Action?.name,
      sortable: true,
      center: true
    },
    {
      name: (
        <div>
          <h2>Date</h2>
        </div>
      ),
      selector: (row: any) => moment.utc(row?.timeStamp).format("DD-MM-YYYY") ,
      sortable: true,
      center: true
    },
    {
      name: (
        <div>
          <h2>Time</h2>
        </div>
      ),
      selector: (row: any) => moment.utc(row?.timeStamp).format("HH:mm"),
      sortable: true,
      center: true
    },
  ];

  const extractRowsData = (actionHistory: any) => {
    let data: any = [];
    actionHistory.map((item: any) => {
      //row.title = item.title
      data.push(item);
    });

    //data = scrapedData
    return data;
  };

  const data = extractRowsData(actionHistory);

  return (
    <Container>
    { (!myHistory) && (
        <div>
        <Breadcrumbs aria-label="breadcrumb" separator="›"> 
        <Link underline="hover" color="inherit"  onClick={()=>navigate("/userslist")}>
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Users List
        </Link>

        <Typography >
          <AddCircleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          User Action History
        </Typography>
      </Breadcrumbs>
      <br/>
      </div>
    )}

    <TableContainer component={Paper}>
      <DataTable columns={columns} data={data} pagination title={<div><h2>{title}</h2><hr/></div>} />
    </TableContainer>
    </Container>
  );
}
