import { Box, Container, Typography } from "@mui/material";
import AdminCharts from "../components/adminCharts";
import AdminCards from "../components/adminCards";

export default function AdminDashboardPage() {
  return (
    <>
      <br />
      <Container>
        <Typography component="h2" variant="h2" align="center" color={"white"}>
          App Statistics
        </Typography>
        <hr />
        <br />

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <AdminCards />
          <br />
          <Container sx={{alignItems: "center"}} >
            <AdminCharts />
          </Container>
          
        </Box>
      </Container>
    </>
  );
}
