import { Card, CardContent, Typography, Skeleton, Container, CardMedia, Box } from '@mui/material';
import { useGetCleanDataBySubjectQuery } from '../redux/api/dataApi';
import { CONFIG } from '../core/constant/config';
import growth from "../assets/stats/growth.png"
import inflation from "../assets/stats/inflation.png"
import population from "../assets/stats/population.png"
import trade from "../assets/stats/trade balance.png"
import unemployment from "../assets/stats/unemployment.png"

import { useMediaQuery } from '@mui/material';



export default function GeneralStats() {
    const encodedString = encodeURIComponent(CONFIG.DASHBOARD.generalStats);
    
    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const {data, isLoading, isFetching} = useGetCleanDataBySubjectQuery(encodedString)
  if (isLoading || isFetching) {
    return <div>   
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
    <br/>
    <Skeleton variant="rectangular" height={15} />
  </div>;
  }

  return (
    <Container sx={{ display: isSmallScreen ? 'block' :'flex' , gap: 4 }}>
        <br/>
        {data?.data.map((item: any, index: any) => (
  <Card key={index} sx={{  width: isSmallScreen ? '100%' : 600, minHeight: isSmallScreen ? 'auto' : 250,  marginBottom:4 }}>
  <CardContent>
    {/* Card content */}
    {item.title.toLowerCase() === "growth" ? (
      <CardMedia
        component="img"
        image={growth}
        alt={item.title.toLowerCase()}
        sx={{ height: 110, width: 110, margin: '0 auto' }}
      />
    ) : item.title.toLowerCase() === "inflation" ? (
      <CardMedia
        component="img"
        image={inflation}
        alt={item.title.toLowerCase()}
        sx={{ height: 110, width: 110, margin: '0 auto' }}
      />
    ) : item.title.toLowerCase() === "population" ? (
      <CardMedia
        component="img"
        image={population}
        alt={item.title.toLowerCase()}
        sx={{ height: 110, width: 110, margin: '0 auto' }}
      />
    ) : item.title.toLowerCase() === "trade balance" ? (
      <CardMedia
        component="img"
        image={trade}
        alt={item.title.toLowerCase()}
        sx={{ height: 110, width: 110, margin: '0 auto' }}
      />
    ) : item.title.toLowerCase() === "unemployment" ? (
      <CardMedia
        component="img"
        image={unemployment}
        alt={item.title.toLowerCase()}
        sx={{ height: 110, width: 110, margin: '0 auto' }}
      />
    ) : (
      <div></div>
    )}
    <br />
    <Typography color={"primary"} fontWeight="bold">
      {item.title.toString().toUpperCase()}
    </Typography>
    {item.value.toString().startsWith('-') ? (
    <Typography variant="body2" component="p" fontWeight="bold" color="error">
      {item.value}
    </Typography>):(
        <Typography variant="body2" component="p" fontWeight="bold" >
        {item.value}
      </Typography>
    )}
    
  </CardContent>
  <Typography
    variant="body2"
    color="primary"
    sx={{ mt: 'auto'}}
    fontWeight="bold"
  >
    {item.date}
  </Typography>
  <br/>
</Card>

))}
  <Box ></Box>
    </Container>
  );
};
