import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useGetRolesQuery} from "../redux/api/roleApi";
import { Role } from "../core/models/types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  id: Number,
  name: String,
) {
  return { id, name };
}

export default function RoleComponent() {
  const {
    data: role,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetRolesQuery();
  if (isLoading || isFetching) {
    return <div>loading...</div>;
  }

  if (isError) {
    if ("status" in error) {
      return <div>{error.status}</div>;
    }
    return <div>Something went wrong.</div>;
  }

  if (!role) {
    return <div>No ROLES ! </div>;
  }
  let rows: any = [] ;
  role.map((rol: Role) => (rows.push(createData(rol.id , rol.name)) ));
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead >
          <TableRow >
            <StyledTableCell><b>ID</b></StyledTableCell>
            <StyledTableCell align="left"><b>Role</b></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="left">{row.name}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}