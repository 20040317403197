import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { Fragment, useState } from 'react';
import { useSignUpMutation } from '../redux/api/authApi';
import { Alert, Avatar } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

//import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const steps = ['Company', 'Representative'];

export default function SignUp() {
  const [signUp] = useSignUpMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [err, setErr] = useState();
  const [company, setCompany] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleNext = async () => {
    const isValid = await trigger(); // validate the form
    if (isValid) {
      if(activeStep === 0){  
        const companyEmailField = document.getElementById('companyEmail') as HTMLInputElement;
        const companyAddressField = document.getElementById('companyAddress') as HTMLInputElement;
        const companyNameField = document.getElementById('companyName') as HTMLInputElement;
        const companyTelField = document.getElementById('companyPhone') as HTMLInputElement;
        const companySectorField = document.getElementById('companySector') as HTMLInputElement;
        let companyValues   = {            
          name       :     companyNameField.value,
          address    :     companyAddressField.value,
          email      :     companyEmailField.value,
          phone      :     companyTelField.value,
          sector     :     companySectorField.value
        }
        setCompany(companyValues)
        setActiveStep(activeStep + 1);
      }
      else if (activeStep === 1) {
        // handle representative details
        const emailField = document.getElementById('email') as HTMLInputElement;
        const firstNameField = document.getElementById('firstName') as HTMLInputElement;
        const lastNameField = document.getElementById('lastName') as HTMLInputElement;
        const telField = document.getElementById('tel') as HTMLInputElement;
        const passField = document.getElementById('password') as HTMLInputElement;
        const userValues = {
          email: emailField.value,
          firstName: firstNameField.value,
          lastName: lastNameField.value,
          tel: telField.value,
          password: passField.value,
          Company: company,
        };
         const response : any = await signUp(userValues);
        if (response.error) {
          setErr(response.error.data);
        } else {
          setActiveStep(activeStep + 1);
          setErr(undefined);
        }
      }
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const { register, formState: { errors }, handleSubmit, trigger} = useForm();
  return (
    <div>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

          { err && 
            <Box>
              <Alert severity="error">{err}</Alert>
              <br/>
            </Box>
          }
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <AccountCircleOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight={500}>
            Sign Up
            </Typography>
            </Box>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Fragment>
              <Typography variant="h5" gutterBottom>
              Thank you for joining us!
              </Typography>
              <Typography variant="subtitle1">
                You can now access to your account via this <Link href='/profile'> link </Link>
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              {activeStep === 0 ? (
                  <Fragment>
                  <Typography  gutterBottom color={"primary"} fontWeight={500} fontSize={20}> Company Details </Typography>
                      <form id='companyForm' onSubmit={handleSubmit(handleNext)}>
                        <Grid item xs={12} >
                          <TextField required id="companyName" label="Company Name"
                            fullWidth  variant="standard" 
                            {...register("companyName", { required: true })}
                            error={Boolean(errors.companyName)}
                            helperText={errors.companyName && "Company name is required"}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField required id="companyAddress" label="Company Address" fullWidth
                             variant="standard"
                             {...register("companyAddress", { required: true })}
                             error={Boolean(errors.companyAddress)}
                             helperText={errors.companyAddress && "Company Address is required"}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField  required id="companyEmail" label="Company Email" fullWidth
                           variant="standard" 
                            {...register('companyEmail', {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                          error={Boolean(errors.companyEmail)}
                          helperText={errors.companyEmail && "Please enter a valid email address"}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField required id="companyPhone" label="Company Phone" fullWidth
                             variant="standard" {...register("companyPhone", {
                              required: "This input is required.",
                              pattern: /^[0-9]{8}$/
                            })}
                            error={Boolean(errors.companyPhone)}
                            helperText={errors.companyPhone && "Company Phone must contain 8 digits!"}
                          />
                        </Grid> 
                        <Grid item xs={12} >
                          <TextField required id="companySector" label="Sector"
                            fullWidth  variant="standard" 
                            {...register("companySector", { required: true })}
                            error={Boolean(errors.companySector)}
                            helperText={errors.companySector && "Sector is required"}
                          />
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button type='submit' variant="contained"  sx={{ mt: 3, ml: 1 }} >
                            Next
                          </Button>
                        </Box>
                      </form>
                    </Fragment>
              ) : (
                <Fragment>
                  <Typography gutterBottom color={"primary"} fontWeight={500} fontSize={20}>
                    Company Representative Details
                  </Typography> 
                  <Box component="form" id='formuser' onSubmit={handleSubmit(handleNext)} noValidate sx={{ mt: 1 }}>
                  
                    <Grid item xs={12} sm={6}>
                      <TextField  required id="firstName" label="First name" fullWidth   
                        variant="standard"
                        {...register("firstName", { required: true })}  
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName && "First name is required"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField  required  id="lastName" label="Last name"
                        fullWidth   variant="standard"
                        {...register("lastName", { required: true, maxLength: 20 })}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName && "Last name is required"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField required id="email" label="Email" fullWidth
                        autoComplete="email" variant="standard"
                        {...register('email', {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })} 
                        error={Boolean(errors.email)}
                        helperText={errors.email && "Please enter a valid email address"}
                      />
                    </Grid> 
                    <Grid item xs={12} sm={12}>
                      <TextField required id="tel" label="Phone Number" fullWidth variant="standard"
                        {...register("tel", {
                          required: "This input is required.",
                          pattern: /^[0-9]{8}$/
                        })}
                        error={Boolean(errors.tel)}
                        helperText={errors.tel && "Phone number must contain exactly 8 numbers"}
                      />  
                    </Grid>
                    <Grid item xs={12}> 
                      <TextField required fullWidth  label="Password" type={showPassword ? "text" : "password"}
                        id="password" autoComplete="password" variant="standard"
                        {...register("password", { required: true })}
                        error={Boolean(errors.password)}
                        helperText={errors.password && "Password is required"}
                        /* InputProps={{ // Add icon button to toggle visibility
                          endAdornment: (
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          )
                        }} */
                      />
                    </Grid>
                    <Grid>
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>  Back </Button>
                        <Button type='submit' variant="contained"  sx={{ mt: 3, ml: 1 }}>
                        Sign Up
                      </Button> 
                    </Grid>
                </Box> 
              </Fragment>
              ) }
                <Grid item>
                <Link href="/login" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
                </Grid>
            </Fragment> 
          )}
        </Paper>
      </Container></div>

  );
}